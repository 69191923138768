import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Coaching from "./assets/Coaching"
import Support from "./assets/Support"
import Webinars from "./assets/Webinars"
import styles from "./styles.module.scss"
import {Locales} from "../../../localization/types";

export const getData = (locale: Locales) => {
	if (locale === 'ru-KZ') {
		return {
			desktopTitle: "Всегда на связи",
			mobileTitle: "Всегда на связи",
			subTitle: "поддержка 24/7",
			content:
				"В нашей службе поддержки работают специалисты ресторанной сферы и разработчики продукта. Они общаются напрямую: по телефону, мессенджерам и почте. Круглосуточно помогают развивать бизнес и готовят полезные материалы.",
			pros: [
				{
					id: 1,
					text: "Бесплатный\nкоучинг",
					icon: <Coaching />,
				},
				{
					id: 2,
					text: "Еженедельные\nвебинары",
					icon: <Webinars />,
				},
				{
					id: 3,
					text: "Онлайн\nи по телефону",
					icon: <Support />,
				},
			],
			illustration: (
				<StaticImage
					className={styles.image}
					src="./assets/support.png"
					breakpoints={[293, 586, 364, 728, 588, 1176]}
					sizes="(max-width: 500px) 293px, (max-width: 1319px) 364px, (min-width: 1320px) 588px"
					alt="Техническая поддержка Quick Resto"
					objectFit="cover"
					placeholder="blurred"
					quality={90}
				/>
			),
		}
	}

	if (locale === 'kz-KZ') {
		return {
			desktopTitle: "Әрдайым байланыста",
			mobileTitle: "Әрдайым байланыста",
			subTitle: "ҚОЛДАУ 24/7",
			content:
				"Біздің қолдау қызметте мейрамхана саласы мамандары мен өнімді әзірлеушілер жұмыс істейді.  Олар тікелей байланысады — телефон, мессенджерлер және пошта арқылы. Тәулік бойы бизнесті дамытуға көмектеседі және пайдалы материалдар дайындайды.",
			pros: [
				{
					id: 1,
					text: "Тегін\nкоучинг",
					icon: <Coaching />,
				},
				{
					id: 2,
					text: "Апта сайынғы\nвебинарлар",
					icon: <Webinars />,
				},
				{
					id: 3,
					text: "Онлайн түрде\nжәне телефон арқылы",
					icon: <Support />,
				},
			],
			illustration: (
				<StaticImage
					className={styles.image}
					src="./assets/support.png"
					breakpoints={[293, 586, 364, 728, 588, 1176]}
					sizes="(max-width: 500px) 293px, (max-width: 1319px) 364px, (min-width: 1320px) 588px"
					alt="Техническая поддержка Quick Resto"
					objectFit="cover"
					placeholder="blurred"
					quality={90}
				/>
			),
		}
	}

	if (locale === 'ru-BY') {
		return {
			desktopTitle: "Всегда на связи",
			mobileTitle: "Всегда на связи",
			subTitle: "поддержка 24/7",
			content:
				"В нашей службе поддержке работают специалисты ресторанной сферы и разработчики продукта. Они общаются напрямую: по телефону, мессенджерам и почте. Круглосуточно помогают развивать бизнес и готовят полезные материалы.",
			pros: [
				{
					id: 1,
					text: "Бесплатный\nкоучинг",
					icon: <Coaching />,
				},
				{
					id: 2,
					text: "Еженедельные\nвебинары",
					icon: <Webinars />,
				},
				{
					id: 3,
					text: "Онлайн\nи по телефону",
					icon: <Support />,
				},
			],
			illustration: (
				<StaticImage
					className={styles.image}
					src="./assets/support.png"
					breakpoints={[293, 586, 364, 728, 588, 1176]}
					sizes="(max-width: 500px) 293px, (max-width: 1319px) 364px, (min-width: 1320px) 588px"
					alt="Техническая поддержка Quick Resto"
					objectFit="cover"
					placeholder="blurred"
					quality={90}
				/>
			),
		}
	}

	return {
		desktopTitle: "Всегда на связи",
		mobileTitle: "Всегда на связи",
		subTitle: "поддержка 24/7",
		content:
			"В нашей поддержке работают специалисты ресторанной сферы и разработчики продукта. Они общаются напрямую — по телефону, мессенджерам и почте. Круглосуточно помогают развивать бизнес и готовят полезные материалы.",
		pros: [
			{
				id: 1,
				text: "Бесплатный\nкоучинг",
				icon: <Coaching />,
			},
			{
				id: 2,
				text: "Еженедельные\nвебинары",
				icon: <Webinars />,
			},
			{
				id: 3,
				text: "Онлайн\nи по телефону",
				icon: <Support />,
			},
		],
		illustration: (
			<StaticImage
				className={styles.image}
				src="./assets/support.png"
				breakpoints={[293, 586, 364, 728, 588, 1176]}
				sizes="(max-width: 500px) 293px, (max-width: 1319px) 364px, (min-width: 1320px) 588px"
				alt="Техническая поддержка Quick Resto"
				objectFit="cover"
				placeholder="blurred"
				quality={90}
			/>
		),
	}
}
