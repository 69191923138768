import React from 'react'

import { HEADER_THEMES } from '../components/_V2/Header'
import { FOOTER_THEMES } from '../components/_V2/Footer'

import DefaultLayout from '../layout/default'

import BusinessTypes from '../components/_V2/BusinessTypes'
import TelegramChats from '../components/_V2/TelegramChats'

import ProductPresentation from '../pagesSections/index/ProductPresentation'
import OurCustomers from '../pagesSections/index/OurCustomers'
import MainFeatures from '../pagesSections/index/MainFeatures'
import TerminalFeatures from '../pagesSections/index/TerminalFeatures'
import CtaForm from '../pagesSections/index/CtaForm'
import CrmFeatures from '../pagesSections/index/CrmFeatures'
import MobileFeatures from '../pagesSections/index/MobileFeatures'
import BackofficeFeatures from '../pagesSections/index/BackofficeFeatures'
import TechSupport from '../pagesSections/index/TechSupport'
import AdditionalServices from '../pagesSections/index/AdditionalServices'
import KnowledgeBase from '../pagesSections/index/KnowledgeBase'
import { getIndexMeta } from '../pagesSections/index/meta'

import { getBusinessTypes } from '../pages-data/_V2/common/business-types'
import ogImage from '../pagesSections/index/ProductPresentation/assets/pres.png'
import styles from '../styles/_V2/pagesStyles/styles.module.scss'
import navStyles from '../components/_V2/Header/components/NavigationDesktop/styles.module.scss'
import { useLocalizationContext } from '../localization/useLocalizationContext'

export default function PageIndex() {
  const localizationContext = useLocalizationContext()
  const metaInfo = getIndexMeta(localizationContext.locale)

  return (
    <DefaultLayout
      key={localizationContext.locale}
      title={metaInfo.title}
      metaTags={metaInfo.metaTags}
      linkCanonical={metaInfo.canonical}
      mainWrapperClassName={navStyles.pageWrapper_background_blue}
      headerTheme={HEADER_THEMES.DEFAULT}
      footerTheme={FOOTER_THEMES.COLORED}
    >
      <ProductPresentation />

      <OurCustomers />

      <MainFeatures className={styles.pageSection} />

      <TerminalFeatures className={styles.pageSection} />

      <MobileFeatures className={styles.pageSection} />

      <CtaForm className={styles.pageSection} />

      <BusinessTypes
        key={localizationContext.locale}
        data={getBusinessTypes(localizationContext.locale)}
        className={styles.pageSection}
      />

      <CrmFeatures />

      <BackofficeFeatures />

      <TechSupport />

      <AdditionalServices className={styles.additionalServices} />

      <KnowledgeBase />

      <TelegramChats title={localizationContext.locale === 'kz-KZ' ? 'Көбірек\nтәжірибелер\nTelegram-да' : 'Больше практик\nв Telegram'} />
    </DefaultLayout>
  )
}
