import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import mediaContent from "./mediaContent.module.scss"
import { pagesLinks } from "../../../pages-data/_V2/common/links"
import {Locales} from "../../../localization/types";

export const getData = (locale: Locales) => {
	if (locale === 'ru-KZ') {
		return {
			features: [
				{
					tabName: "Автоматизация",
					desktopTitle: "Преврати ресторан в систему за 10 минут",
					tabletTitle: "Преврати ресторан в систему \nза 10 минут",
					mobileTitle: "Преврати ресторан в систему за 10 минут",
					desktopContent:
						"Управление кухней, складом, работа зала и доставка теперь в удобном интерфейсе. Автоматизация всех процессов с выгодой до 1.5 млн тенге в год.",
					tabletContent:
						"Управление кухней, складом, работа зала и доставка теперь в удобном интерфейсе. Автоматизация всех процессов с выгодой до 1.5 млн тенге в год.",
					mobileContent:
						"Управление кухней, складом, работа зала и доставка теперь \nв удобном интерфейсе. Автоматизация всех процессов \nс выгодой до 1.5 млн тенге в год.",
					detailLink: pagesLinks.bo,
					mediaContent: (
						<StaticImage
							className={mediaContent.imageWrapper}
							src="./assets/index-features-automatization_kz.png"
							breakpoints={[288, 576, 682, 1364, 824, 1649]}
							sizes="(max-width: 500px) 288px, (max-width: 1319px) 824px, (min-width: 1320px) 682px"
							alt="Автоматизация процессов в ресторане"
							objectFit="contain"
							objectPosition="center"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
				{
					tabName: "CRM",
					desktopTitle: "Работай с гостями \nпо своим правилам",
					mobileTitle: "Работай \nс гостями \nпо своим \nправилам",
					desktopContent:
						"Создавай акции и спецпредложения, чтобы разнообразить опыт гостей и изучать их предпочтения. Подключи бонусную программу и оплату баллами — мотивируй заходить чаще.",
					tabletContent:
						"Создавай акции и спецпредложения, чтобы разнообразить опыт гостей и изучать их предпочтения. Подключи бонусную программу и оплату баллами — мотивируй заходить чаще.",
					mobileContent:
						"Создавай акции и спецпредложения, чтобы разнообразить опыт гостей \nи изучать их предпочтения. Подключи бонусную программу \nи оплату баллами — мотивируй заходить чаще.",
					detailLink: pagesLinks.marketing,
					mediaContent: (
						<StaticImage
							className={mediaContent.imageWrapper}
							src="./assets/index-features-crm_kz.png"
							breakpoints={[288, 576, 682, 1364, 824, 1649]}
							sizes="(max-width: 500px) 288px, (max-width: 1319px) 824px, (min-width: 1320px) 682px"
							alt="Программы лояльности для ресторанов"
							width={824}
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
				{
					tabName: "Касса и зал",
					desktopTitle: "Начать проще, \nчем кажется",
					desktopContent:
						"В зале, за стойкой или навынос — все типы заказов в одном понятном интерфейсе. Система экономит время и бережёт от ошибок. Не сомневайся: всё продолжит работать, даже если пропадёт интернет.",
					mobileContent:
						"В зале, за стойкой или навынос — все типы заказов в одном понятном интерфейсе. Система экономит время и бережет от ошибок. Будь уверен — все будет работать даже если пропадет интернет.",
					detailLink: pagesLinks.terminal,
					mediaContent: (
						<StaticImage
							className={mediaContent.imageWrapper}
							src="./assets/index-features-hall_kz.png"
							breakpoints={[288, 576, 682, 1364, 824, 1649]}
							sizes="(max-width: 500px) 288px, (max-width: 1319px) 824px, (min-width: 1320px) 682px"
							alt="Касса на планшете Quick Resto"
							width={824}
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
				{
					tabName: "Кухня и склад",
					desktopTitle: "Настрой кухню \nна прибыль",
					desktopContent:
						"Составлять техкарты легко: система сама рассчитает\nвес и себестоимость блюда. Учёт и списание продуктов\nна складе ведутся автоматически. А оповещения\nподскажут, когда надо пополнить запасы.",
					tabletContent:
						"Составь техкарты. Система рассчитает вес и себестоимость блюда. Учет и списание продуктов на складе ведется автоматически. Оповещения подскажут, когда надо пополнить запас.",
					mobileContent:
						"Составь техкарты. Система рассчитает вес и себестоимость блюда. Учет и списание продуктов на складе ведется автоматически. Оповещения подскажут, когда надо пополнить запас.",
					detailLink: pagesLinks.bo,
					mediaContent: (
						<StaticImage
							className={mediaContent.imageWrapper}
							src="./assets/index-features-warehouse_kz.png"
							breakpoints={[288, 576, 682, 1364, 824, 1649]}
							sizes="(max-width: 500px) 288px, (max-width: 1319px) 824px, (min-width: 1320px) 682px"
							alt="Автоматизация ресторанного бизнеса"
							width={824}
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
			],
		}
	}

	if (locale === 'kz-KZ') {
		return {
			features: [
				{
					tabName: "Автоматтандыру",
					desktopTitle: "Өз мейрамханаңызды 10 минут ішінде жүйеге айналдырыңыз",
					tabletTitle: "Өз мейрамханаңызды 10 минут ішінде\nжүйеге айналдырыңыз",
					mobileTitle: "Өз мейрамханаңызды 10 минут ішінде жүйеге айналдырыңыз",
					desktopContent:
						"Асүйді, қойманы басқару, залдың жұмысы және жеткізу енді ыңғайлы интерфейсте. Жылына 1,5 млн теңгеге дейін пайдамен барлық процестерді автоматтандыру.",
					tabletContent:
						"Асүйді, қойманы басқару, залдың жұмысы және жеткізу енді ыңғайлы интерфейсте. Жылына 1,5 млн теңгеге дейін пайдамен барлық процестерді автоматтандыру.",
					mobileContent:
						"Асүйді, қойманы басқару, залдың жұмысы және жеткізу енді ыңғайлы интерфейсте. Жылына 1,5 млн теңгеге\nдейін пайдамен барлық процестерді автоматтандыру.",
					detailLink: pagesLinks.bo,
					detailText: "Толығырақ",
					mediaContent: (
						<StaticImage
							className={mediaContent.imageWrapper}
							src="./assets/index-features-automatization_kz.png"
							breakpoints={[288, 576, 682, 1364, 824, 1649]}
							sizes="(max-width: 500px) 288px, (max-width: 1319px) 824px, (min-width: 1320px) 682px"
							alt="Автоматизация процессов в ресторане"
							objectFit="contain"
							objectPosition="center"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
				{
					tabName: "CRM",
					desktopTitle: "Қонақтармен өз ережелеріңіз бойынша жұмыс істеңіз",
					mobileTitle: "Қонақтармен өз\nережелеріңіз\nбойынша жұмыс\nістеңіз",
					desktopContent:
						"Қонақтардың тәжірибесін түрлендіру және олардың қалауларын білу үшін акциялар мен арнайы ұсыныстарды жасаңыз. Бонустық бағдарламаны және баллдармен төлеуді қосыңыз — жиірек кіруге ынталандырыңыз.",
					tabletContent:
						"Қонақтардың тәжірибесін түрлендіру және олардың қалауларын білу үшін акциялар мен арнайы ұсыныстарды жасаңыз. Бонустық бағдарламаны және баллдармен төлеуді қосыңыз — жиірек кіруге ынталандырыңыз.",
					mobileContent:
						"Қонақтардың тәжірибесін түрлендіру және олардың қалауларын білу үшін акциялар мен арнайы ұсыныстарды жасаңыз. Бонустық бағдарламаны\nжәне баллдармен төлеуді қосыңыз — жиірек\nкіруге ынталандырыңыз.",
					detailLink: pagesLinks.marketing,
					detailText: "Толығырақ",
					mediaContent: (
						<StaticImage
							className={mediaContent.imageWrapper}
							src="./assets/index-features-crm_kz.png"
							breakpoints={[288, 576, 682, 1364, 824, 1649]}
							sizes="(max-width: 500px) 288px, (max-width: 1319px) 824px, (min-width: 1320px) 682px"
							alt="Программы лояльности для ресторанов"
							width={824}
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
				{
					tabName: "Касса мен зал",
					desktopTitle: "Бастау көрінгеннен\nоңай",
					desktopContent:
						"Залда, үстелшені алдында немесе өзімен әкету — тапсырыстардың барлық түрлері бір түсінікті интерфейсте. Жүйе уақытты үнемдейді және қателерден құтқарады. Сенімді болыңыз — тіпті интернет жоғалып кетсе де, барлығы жұмйс істейді.",
					mobileContent:
						"Залда, үстелшені алдында немесе өзімен әкету — тапсырыстардың барлық түрлері бір түсінікті интерфейсте. Жүйе уақытты үнемдейді және қателерден құтқарады. Сенімді болыңыз — тіпті интернет жоғалып кетсе де, барлығы жұмйс істейді.",
					detailLink: pagesLinks.terminal,
					detailText: "Толығырақ",
					mediaContent: (
						<StaticImage
							className={mediaContent.imageWrapper}
							src="./assets/index-features-hall_kz.png"
							breakpoints={[288, 576, 682, 1364, 824, 1649]}
							sizes="(max-width: 500px) 288px, (max-width: 1319px) 824px, (min-width: 1320px) 682px"
							alt="Касса на планшете Quick Resto"
							width={824}
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
				{
					tabName: "Асүй мен қойма",
					desktopTitle: "Асүйді пайда\nалуға теңшеңіз",
					desktopContent:
						"Техкарталарды жасаңыз. Жүйе тағамдың салмағы мен өзіндік құнын есептейді. Қоймада өнімдерді есепке алу және есептен шығару автоматты түрде жүргізіледі. Хабарламалар қорды қашан толтыру керектігін айтып береді.",
					tabletContent:
						"Техкарталарды жасаңыз. Жүйе тағамдың салмағы мен өзіндік құнын есептейді. Қоймада өнімдерді есепке алу және есептен шығару автоматты түрде жүргізіледі. Хабарламалар қорды қашан толтыру керектігін айтып береді.",
					mobileContent:
						"Техкарталарды жасаңыз. Жүйе тағамдың салмағы мен өзіндік құнын есептейді. Қоймада өнімдерді есепке алу және есептен шығару автоматты түрде жүргізіледі. Хабарламалар қорды қашан толтыру керектігін айтып береді.",
					detailLink: pagesLinks.bo,
					mediaContent: (
						<StaticImage
							className={mediaContent.imageWrapper}
							src="./assets/index-features-warehouse_kz.png"
							breakpoints={[288, 576, 682, 1364, 824, 1649]}
							sizes="(max-width: 500px) 288px, (max-width: 1319px) 824px, (min-width: 1320px) 682px"
							alt="Автоматизация ресторанного бизнеса"
							width={824}
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
			],
		}
	}

	if (locale === 'ru-BY') {
		return {
			features: [
				{
					tabName: "Автоматизация",
					desktopTitle: "Мы всё продумали. Бери и зарабатывай",
					tabletTitle: "Мы всё продумали. \nБери и зарабатывай",
					mobileTitle: "Мы всё продумали. Бери и зарабатывай",
					desktopContent:
						"Управление кухней, складом, работа зала и доставка теперь в удобном интерфейсе. Автоматизация всех процессов с выгодой до 8 000 р. в год.",
					tabletContent:
						"Управление кухней, складом, работа зала и доставка теперь в удобном интерфейсе. Автоматизация всех процессов с выгодой до 8 000 р. в год.",
					mobileContent:
						"Управление кухней, складом, работа зала и доставка теперь в \nудобном интерфейсе. \nАвтоматизация всех процессов с \nвыгодой до 8 000 р. в год.",
					detailLink: pagesLinks.bo,
					mediaContent: (
						<StaticImage
							className={mediaContent.imageWrapper}
							src="./assets/index-features-automatization_by.png"
							breakpoints={[288, 576, 682, 1364, 824, 1649]}
							sizes="(max-width: 500px) 288px, (max-width: 1319px) 824px, (min-width: 1320px) 682px"
							alt="Автоматизация процессов в ресторане"
							objectFit="contain"
							objectPosition="center"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
				{
					tabName: "CRM",
					desktopTitle: "Особый подход \nк каждому гостю",
					desktopContent:
						"Создавай акции и спец предложения, чтобы \nразнообразить вкусы и опыт гостей. Подключи \nбонусную программу и оплату баллами и мотивируй \nпосещать тебя чаще.",
					tabletContent:
						"Создавай акции и спец предложения, чтобы разнообразить вкусы и опыт гостей. Подключи бонусную программу и оплату баллами и мотивируй посещать тебя чаще.",
					mobileContent:
						"Создавай акции и спец предложения, чтобы разнообразить вкусы и опыт гостей. Подключи \nбонусную программу и оплату баллами и мотивируй посещать тебя чаще.",
					detailLink: pagesLinks.marketing,
					mediaContent: (
						<StaticImage
							className={mediaContent.imageWrapper}
							src="./assets/index-features-crm_by.png"
							breakpoints={[288, 576, 682, 1364, 824, 1649]}
							sizes="(max-width: 500px) 288px, (max-width: 1319px) 824px, (min-width: 1320px) 682px"
							alt="Программы лояльности для ресторанов"
							width={824}
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
				{
					tabName: "Касса и зал",
					desktopTitle: "Начни просто. \nБез обучения.",
					desktopContent:
						"В зале, за стойкой или навынос — все типы заказов в одном понятном интерфейсе. Система экономит время и бережёт от ошибок. Будь уверен — все будет работать, даже если пропадет интернет.",
					detailLink: pagesLinks.terminal,
					mediaContent: (
						<StaticImage
							className={mediaContent.imageWrapper}
							src="./assets/index-features-hall_by.png"
							breakpoints={[288, 576, 682, 1364, 824, 1649]}
							sizes="(max-width: 500px) 288px, (max-width: 1319px) 824px, (min-width: 1320px) 682px"
							alt="Касса на планшете Quick Resto"
							width={824}
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
				{
					tabName: "Кухня и склад",
					desktopTitle: "Управляй процессом \nи результатом",
					desktopContent:
						"Составь техкарты. Система рассчитает вес и себестоимость блюда. Учёт и списание продуктов на складе ведется автоматически. Оповещения подскажут, когда надо пополнить запас.",
					detailLink: pagesLinks.bo,
					mediaContent: (
						<StaticImage
							className={mediaContent.imageWrapper}
							src="./assets/index-features-warehouse_by.png"
							breakpoints={[288, 576, 682, 1364, 824, 1649]}
							sizes="(max-width: 500px) 288px, (max-width: 1319px) 824px, (min-width: 1320px) 682px"
							alt="Автоматизация ресторанного бизнеса"
							width={824}
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
			],
		}
	}

	return {
		features: [
			{
				tabName: "Автоматизация",
				desktopTitle: "Преврати ресторан в систему за 10 минут",
				tabletTitle: "Преврати ресторан в систему за 10 минут",
				mobileTitle: "Преврати ресторан в систему за 10 минут",
				desktopContent:
					"Управление кухней, складом, работа зала и доставка теперь в удобном интерфейсе. Автоматизация всех процессов с выгодой до 300 000 ₽ в год.",
				tabletContent:
					"Управление кухней, складом, работа зала и доставка теперь в удобном интерфейсе. Автоматизация всех процессов с выгодой до 300 000 ₽ в год.",
				mobileContent:
					"Управление кухней, складом, работа зала и доставка теперь \nв удобном интерфейсе. Автоматизация всех процессов \nс выгодой до 300 000 ₽ в год.",
				detailLink: pagesLinks.bo,
				mediaContent: (
					<StaticImage
						className={mediaContent.imageWrapper}
						src="./assets/index-features-automatization.png"
						breakpoints={[288, 576, 682, 1364, 824, 1649]}
						sizes="(max-width: 500px) 288px, (max-width: 1319px) 824px, (min-width: 1320px) 682px"
						alt="Автоматизация процессов в ресторане"
						objectFit="contain"
						objectPosition="center"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
			{
				tabName: "CRM",
				desktopTitle: "Ближе к гостям. Без посредников.",
				desktopContent:
					"Создавай акции и спец предложения, чтобы разнообразить вкусы и опыт гостей. Подключи \nбонусную программу и оплату баллами и мотивируй посещать тебя чаще.",
				tabletContent:
					"Создавай акции и спец предложения, чтобы разнообразить вкусы и опыт гостей. Подключи бонусную программу и оплату баллами и мотивируй посещать тебя чаще.",
				mobileContent:
					"Создавай акции и спец предложения, чтобы разнообразить вкусы и опыт гостей. Подключи \nбонусную программу и оплату баллами и мотивируй посещать тебя чаще.",
				detailLink: pagesLinks.marketing,
				mediaContent: (
					<StaticImage
						className={mediaContent.imageWrapper}
						src="./assets/index-features-crm.png"
						breakpoints={[288, 576, 682, 1364, 824, 1649]}
						sizes="(max-width: 500px) 288px, (max-width: 1319px) 824px, (min-width: 1320px) 682px"
						alt="Программы лояльности для ресторанов"
						width={824}
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
			{
				tabName: "Касса и зал",
				desktopTitle: "Начни просто. Без обучения.",
				desktopContent:
					"В зале, за стойкой или навынос — все типы заказов в одном понятном интерфейсе. Система экономит время и бережет от ошибок. Будь уверен — все будет работать даже если пропадет интернет.",
				detailLink: pagesLinks.terminal,
				mediaContent: (
					<StaticImage
						className={mediaContent.imageWrapper}
						src="./assets/index-features-hall.png"
						breakpoints={[288, 576, 682, 1364, 824, 1649]}
						sizes="(max-width: 500px) 288px, (max-width: 1319px) 824px, (min-width: 1320px) 682px"
						alt="Касса на планшете Quick Resto"
						width={824}
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
			{
				tabName: "Кухня и склад",
				desktopTitle: "Запусти кухню со вкусом и прибылью",
				desktopContent:
					"Составь техкарты. Система рассчитает вес и себестоимость блюда. Учет и списание продуктов на складе ведется автоматически. Оповещения подскажут, когда надо пополнить запас.",
				detailLink: pagesLinks.bo,
				mediaContent: (
					<StaticImage
						className={mediaContent.imageWrapper}
						src="./assets/index-features-warehouse.png"
						breakpoints={[288, 576, 682, 1364, 824, 1649]}
						sizes="(max-width: 500px) 288px, (max-width: 1319px) 824px, (min-width: 1320px) 682px"
						alt="Автоматизация ресторанного бизнеса"
						width={824}
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
		],
	}
}
