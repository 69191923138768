import * as React from "react"
import { SVGProps } from "react"

const TableMapBackdropIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 404 495"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M237.591 1.52c0-.84.68-1.52 1.519-1.52h15.597c.839 0 1.519.68 1.519 1.52v4.692h1.587c.839 0 1.519.68 1.519 1.519v3.173c0 .84-.68 1.52-1.519 1.52h-1.587v31.012c8.227 3.594 13.976 11.803 13.976 21.355v85.515h95.783c2.03 0 3.047 2.455 1.612 3.89L263.29 258.503c-.89.89-2.333.89-3.223 0L155.761 154.196c-1.436-1.435-.419-3.89 1.611-3.89h13.115c.203-.258.325-.584.325-.938v-26.651c-1.921-.22-2.869-.645-4.258-1.267-.426-.191-.894-.4-1.444-.628a27.212 27.212 0 0 1-8.832-5.902 27.214 27.214 0 0 1-5.903-8.833c-2.717-6.561-1.207-13.904.504-20.796l5.233-21.072a1.519 1.519 0 0 1 1.474-1.153h35.888c.698 0 1.306.476 1.474 1.153l5.232 21.072c1.712 6.892 3.222 14.235.505 20.796a27.23 27.23 0 0 1-14.736 14.735c-.549.228-1.017.437-1.443.628-1.389.622-2.337 1.047-4.257 1.267v26.651c0 .354.121.68.325.938h43.041V64.791c0-9.552 5.749-17.76 13.976-21.355V12.423h-1.587a1.52 1.52 0 0 1-1.519-1.519V7.731c0-.84.68-1.52 1.519-1.52h1.587V1.52ZM135.637 151.64a4.557 4.557 0 0 0-3.223-1.335h-9.695c7.648-2.9 13.085-10.295 13.085-18.959 0-1.222-.99-2.212-2.212-2.212H31.442c-1.22 0-2.21.99-2.21 2.212 0 8.664 5.436 16.059 13.084 18.959H31.058C13.905 150.306 0 164.212 0 181.365v25.674a2.278 2.278 0 0 0 2.279 2.278H177.86a2.28 2.28 0 0 1 2.279 2.279v196.556c0 30.018-24.334 54.352-54.352 54.352H109.43a2.28 2.28 0 0 0-2.279 2.279v27.804a2.28 2.28 0 0 0 2.279 2.279h184.899a2.28 2.28 0 0 0 2.279-2.279v-27.804a2.28 2.28 0 0 0-2.279-2.279h-16.356c-30.018 0-54.353-24.335-54.353-54.352V240.569c0-.605-.24-1.184-.667-1.611l-87.316-87.317Zm198.299 53.786c-1.436 1.436-.419 3.89 1.611 3.89h65.933a2.278 2.278 0 0 0 2.279-2.278v-25.674c0-11.801-6.581-22.065-16.275-27.321-.848-.46-1.891-.275-2.573.408l-50.975 50.975Z"
			fill="#E5EEFF"
		/>
	</svg>
)

export default TableMapBackdropIcon
