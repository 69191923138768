import * as React from "react"

const Webinars = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		width={32}
		height={32}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<rect
			x={2}
			y={3.232}
			width={28}
			height={19.48}
			rx={2}
			stroke="#3021E5"
			strokeWidth={2}
		/>
		<path
			d="M8.696 28.768h14.608M13.565 22.884v5.884M18.435 22.884v5.884"
			stroke="#3021E5"
			strokeWidth={2}
			strokeLinecap="round"
		/>
		<path
			d="M11.68 12.408v4.08c0 1.084 1.925 1.962 4.3 1.962s4.3-.878 4.3-1.962v-4.08m-11.604-1.51 7.304-3.405 7.305 3.405-7.305 3.404-7.304-3.404Z"
			stroke="#3021E5"
			strokeWidth={2}
			strokeLinejoin="round"
		/>
	</svg>
)

export default Webinars
