import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { externalLinks, pagesLinks } from "../../../pages-data/_V2/common/links"
import styles from "./styles.module.scss";
import { Locales } from "../../../localization/types";

export const getData = (locale: Locales) => {
	if (locale === "ru-KZ") {
		return {
			...commonLocaleData,
			desktopTitle: "Инструменты для рестораторов и первооткрывателей",
			mobileTitle: (
				<>
					Инструменты <br />
					для рестораторов и <br />
					первооткрывателей
				</>
			),
			subTitle: "Автоматизируй процессы, привлекай гостей,\nумножай прибыль",
			desktopCtaDetails:(
				<div className={styles.stores}>
					<StaticImage
						className={styles.storeImage}
						src="./assets/apple.png"
						width={38}
						objectFit="contain"
						placeholder="blurred"
						alt="App Store"
					/>
					<StaticImage
						className={styles.storeImage}
						src="./assets/playmarket.png"
						width={38}
						objectFit="contain"
						placeholder="blurred"
						alt="Play Market"
					/>
					<StaticImage
						className={styles.storeImage}
						src="./assets/windows.png"
						width={38}
						objectFit="contain"
						placeholder="blurred"
						alt="Microsoft Store"
					/>
				</div>
			),
			tabletCtaDetails:(
				<div className={styles.stores}>
					<StaticImage
						className={styles.storeImage}
						src="./assets/apple.png"
						width={38}
						objectFit="contain"
						placeholder="blurred"
						alt="App Store"
					/>
					<StaticImage
						className={styles.storeImage}
						src="./assets/playmarket.png"
						width={38}
						objectFit="contain"
						placeholder="blurred"
						alt="Play Market"
					/>
					<StaticImage
						className={styles.storeImage}
						src="./assets/windows.png"
						width={38}
						objectFit="contain"
						placeholder="blurred"
						alt="Microsoft Store"
					/>
				</div>
			),
			mobileCtaDetails:(
				<div className={styles.stores}>
					<StaticImage
						className={styles.storeImage}
						src="./assets/apple.png"
						width={38}
						objectFit="contain"
						placeholder="blurred"
						alt="App Store"
					/>
					<StaticImage
						className={styles.storeImage}
						src="./assets/playmarket.png"
						width={38}
						objectFit="contain"
						placeholder="blurred"
						alt="Play Market"
					/>
					<StaticImage
						className={styles.storeImage}
						src="./assets/windows.png"
						width={38}
						objectFit="contain"
						placeholder="blurred"
						alt="Microsoft Store"
					/>
				</div>
			),
			mobileNoBreak: true,
			figure: (
				<StaticImage
					className={styles.terminalImage}
					src="./assets/pres_kz.png"
					alt="Система автоматизации Quick Resto"
					breakpoints={[337, 674, 870, 1740, 821, 1642]}
					sizes="(max-width: 500px) 337px, (max-width: 1319px) 870px, (min-width: 1320px) 821px"
					objectFit="contain"
					placeholder="blurred"
					quality={100}
				/>
			),
			bottomCtaDetails: "Быстро, удобно и две недели бесплатно."
		};
	}

	if (locale === "kz-KZ") {
		return {
			...commonLocaleData,
			desktopTitle: "Рестораторлар мен алғаш ашушыларға арналған құралдар",
			mobileTitle: (
				<>
					Рестораторлар мен <br />
					алғаш ашушыларға арналған<br />
					құралдар
				</>
			),
			subTitle: "Процестерді автоматтандырыңыз, қонақтарды тартыңыз, пайданы көбейтіңіз",
			desktopCtaDetails:(
				<div className={styles.stores}>
					<StaticImage
						className={styles.storeImage}
						src="./assets/apple.png"
						width={38}
						objectFit="contain"
						placeholder="blurred"
						alt="App Store"
					/>
					<StaticImage
						className={styles.storeImage}
						src="./assets/playmarket.png"
						width={38}
						objectFit="contain"
						placeholder="blurred"
						alt="Play Market"
					/>
					<StaticImage
						className={styles.storeImage}
						src="./assets/windows.png"
						width={38}
						objectFit="contain"
						placeholder="blurred"
						alt="Microsoft Store"
					/>
				</div>
			),
			tabletCtaDetails:(
				<div className={styles.stores}>
					<StaticImage
						className={styles.storeImage}
						src="./assets/apple.png"
						width={38}
						objectFit="contain"
						placeholder="blurred"
						alt="App Store"
					/>
					<StaticImage
						className={styles.storeImage}
						src="./assets/playmarket.png"
						width={38}
						objectFit="contain"
						placeholder="blurred"
						alt="Play Market"
					/>
					<StaticImage
						className={styles.storeImage}
						src="./assets/windows.png"
						width={38}
						objectFit="contain"
						placeholder="blurred"
						alt="Microsoft Store"
					/>
				</div>
			),
			mobileCtaDetails:(
				<div className={styles.stores}>
					<StaticImage
						className={styles.storeImage}
						src="./assets/apple.png"
						width={38}
						objectFit="contain"
						placeholder="blurred"
						alt="App Store"
					/>
					<StaticImage
						className={styles.storeImage}
						src="./assets/playmarket.png"
						width={38}
						objectFit="contain"
						placeholder="blurred"
						alt="Play Market"
					/>
					<StaticImage
						className={styles.storeImage}
						src="./assets/windows.png"
						width={38}
						objectFit="contain"
						placeholder="blurred"
						alt="Microsoft Store"
					/>
				</div>
			),
			mobileNoBreak: true,
			figure: (
				<StaticImage
					className={styles.terminalImage}
					src="./assets/pres_kz.png"
					alt="Система автоматизации Quick Resto"
					breakpoints={[337, 674, 870, 1740, 821, 1642]}
					sizes="(max-width: 500px) 337px, (max-width: 1319px) 870px, (min-width: 1320px) 821px"
					objectFit="contain"
					placeholder="blurred"
					quality={100}
				/>
			),
			bottomCtaDetails: "Тез, ыңғайлы және екі апта тегін.",
			cta: "Тегін бастау"
		};
	}

	if (locale === "ru-BY") {
		return {
			...commonLocaleData,
			desktopTitle: "Решения для рестораторов и предпринимателей",
			mobileTitle: "Решения для рестораторов и предпринимателей",
			subTitle: "Автоматизируй процессы, привлекай гостей\nи зарабатывай",
			desktopCtaDetails:(
				<div className={styles.stores}>
					<StaticImage
						className={styles.storeImage}
						src="./assets/apple.png"
						width={38}
						objectFit="contain"
						placeholder="blurred"
						alt="App Store"
					/>
					<StaticImage
						className={styles.storeImage}
						src="./assets/playmarket.png"
						width={38}
						objectFit="contain"
						placeholder="blurred"
						alt="Play Market"
					/>
					<StaticImage
						className={styles.storeImage}
						src="./assets/windows.png"
						width={38}
						objectFit="contain"
						placeholder="blurred"
						alt="Microsoft Store"
					/>
				</div>
			),
			tabletCtaDetails:(
				<div className={styles.stores}>
					<StaticImage
						className={styles.storeImage}
						src="./assets/apple.png"
						width={38}
						objectFit="contain"
						placeholder="blurred"
						alt="App Store"
					/>
					<StaticImage
						className={styles.storeImage}
						src="./assets/playmarket.png"
						width={38}
						objectFit="contain"
						placeholder="blurred"
						alt="Play Market"
					/>
					<StaticImage
						className={styles.storeImage}
						src="./assets/windows.png"
						width={38}
						objectFit="contain"
						placeholder="blurred"
						alt="Microsoft Store"
					/>
				</div>
			),
			mobileCtaDetails:(
				<div className={styles.stores}>
					<StaticImage
						className={styles.storeImage}
						src="./assets/apple.png"
						width={38}
						objectFit="contain"
						placeholder="blurred"
						alt="App Store"
					/>
					<StaticImage
						className={styles.storeImage}
						src="./assets/playmarket.png"
						width={38}
						objectFit="contain"
						placeholder="blurred"
						alt="Play Market"
					/>
					<StaticImage
						className={styles.storeImage}
						src="./assets/windows.png"
						width={38}
						objectFit="contain"
						placeholder="blurred"
						alt="Microsoft Store"
					/>
				</div>
			),
			mobileNoBreak: true,
			figure: (
				<StaticImage
					className={styles.terminalImage}
					src="./assets/pres_by.png"
					alt="Система автоматизации Quick Resto"
					breakpoints={[337, 674, 870, 1740, 821, 1642]}
					sizes="(max-width: 500px) 337px, (max-width: 1319px) 870px, (min-width: 1320px) 821px"
					objectFit="contain"
					placeholder="blurred"
					quality={100}
				/>
			),
			bottomCtaDetails: "Быстро, удобно и две недели бесплатно."
		};
	}

	return {
		...commonLocaleData,
		desktopTitle: "Решения для рестораторов и первооткрывателей",
		mobileTitle: "Решения для рестораторов и первооткрывателей",
		subTitle: "Автоматизируй процессы, привлекай гостей, увеличивай прибыль. Quick Resto входит в реестр российского ПО.",
		desktopCtaDetails:(
			<div className={styles.stores}>
				<StaticImage
					className={styles.storeImage}
					src="./assets/apple.png"
					width={38}
					objectFit="contain"
					placeholder="blurred"
					alt="App Store"
				/>
				<StaticImage
					className={styles.storeImage}
					src="./assets/playmarket.png"
					width={38}
					objectFit="contain"
					placeholder="blurred"
					alt="Play Market"
				/>
				<StaticImage
					className={styles.storeImage}
					src="./assets/windows.png"
					width={38}
					objectFit="contain"
					placeholder="blurred"
					alt="Microsoft Store"
				/>
			</div>
		),
		tabletCtaDetails:(
			<div className={styles.stores}>
				<StaticImage
					className={styles.storeImage}
					src="./assets/apple.png"
					width={38}
					objectFit="contain"
					placeholder="blurred"
					alt="App Store"
				/>
				<StaticImage
					className={styles.storeImage}
					src="./assets/playmarket.png"
					width={38}
					objectFit="contain"
					placeholder="blurred"
					alt="Play Market"
				/>
				<StaticImage
					className={styles.storeImage}
					src="./assets/windows.png"
					width={38}
					objectFit="contain"
					placeholder="blurred"
					alt="Microsoft Store"
				/>
			</div>
		),
		mobileCtaDetails:(
			<div className={styles.stores}>
				<StaticImage
					className={styles.storeImage}
					src="./assets/apple.png"
					width={38}
					objectFit="contain"
					placeholder="blurred"
					alt="App Store"
				/>
				<StaticImage
					className={styles.storeImage}
					src="./assets/playmarket.png"
					width={38}
					objectFit="contain"
					placeholder="blurred"
					alt="Play Market"
				/>
				<StaticImage
					className={styles.storeImage}
					src="./assets/windows.png"
					width={38}
					objectFit="contain"
					placeholder="blurred"
					alt="Microsoft Store"
				/>
			</div>
		),
		mobileNoBreak: true,
		figure: (
			<StaticImage
				className={styles.terminalImage}
				src="./assets/pres.png"
				alt="Система автоматизации Quick Resto"
				breakpoints={[337, 674, 870, 1740, 821, 1642]}
				sizes="(max-width: 500px) 337px, (max-width: 1319px) 870px, (min-width: 1320px) 821px"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),
		bottomCtaDetails:
			"За 10 минут, без дорогого оборудования, с удобным интерфейсом. И 2 недели бесплатно.",
	};

};

const commonLocaleData = {
	cta: pagesLinks.registration.text,
	ctaLink: pagesLinks.registration,
};
