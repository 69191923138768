import classNames from "classnames"
import React from "react"
import { getData } from "./data"
import styles from "./styles.module.scss"
import {useLocalizationContext} from "../../../localization/useLocalizationContext";

interface Props {
	className?: string
}

export default function TechSupport(props: Props) {
	const localizationContext = useLocalizationContext();

	return (
		<section className={classNames(styles.section, props.className)}>
			<div className={styles.content}>
				<article className={styles.heading}>
					<p className={styles.subTitle}>{getData(localizationContext.locale).subTitle}</p>
					<div>
						<h2
							className={classNames(
								styles.title,
								styles.adaptiveContent__desktop
							)}
						>
							{getData(localizationContext.locale).desktopTitle}
						</h2>
						<noindex>
							<span
								className={classNames(
									styles.title,
									styles.adaptiveContent__mobile
								)}
							>
								{getData(localizationContext.locale).mobileTitle}
							</span>
						</noindex>
					</div>

					<p className={styles.headingContent}>{getData(localizationContext.locale).content}</p>

					<p className={styles.footnote}>{getData(localizationContext.locale).footnote}</p>
				</article>

				<ul className={styles.pros}>
					{getData(localizationContext.locale).pros.map(pro => (
						<li className={classNames(styles.pro)} key={pro.id}>
							{pro.icon}
							<p className={styles.proText}>{pro.text}</p>
						</li>
					))}
				</ul>
				<div className={styles.illustrationWrapper}>
					<figure className={styles.illustration}>{getData(localizationContext.locale).illustration}</figure>
					<div className={styles.illustrationBackdropPattern} />
				</div>
			</div>
		</section>
	)
}
