import * as React from "react"

const BusinessMoneyFoodBackdrop = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 368 126"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path d="m367.055 40.607-7.697 55.16c-2.477 17.751-17.962 30.763-35.874 30.143l-285.826-9.884c-17.11-.592-31.282-13.475-33.496-30.453L.824 59.983c-2.613-20.034 12.23-38.114 32.39-39.45L330.075.845c22.144-1.468 40.047 17.78 36.98 39.76Z" />
	</svg>
)

export default BusinessMoneyFoodBackdrop
