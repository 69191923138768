import classNames from "classnames"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { externalLinks } from "../../../pages-data/_V2/common/links"
import BusinessMoneyFoodBackdrop from "./assets/BusinessMoneyFoodBackdrop"
import QRCommunityBackdrop from "./assets/QRCommunityBackdrop"
import QRCommunityIcon from "./assets/QRCommunityIcon"
import SupportBotBackdrop from "./assets/SupportBotBackdrop"
import SupportBotIcon from "./assets/SupportBotIcon"
import styles from "./styles.module.scss"
import {Locales} from "../../../localization/types";

export const getData = (locale :Locales) => {

	if (locale === 'kz-KZ') {
		return {
			communityChats: [
				{
					className: styles.__businessMoneyFood,
					title: (
						<>
							Бизнес, ақша,<br />
							қоғамдық тамақтандыру
						</>
					),
					description:(
						<>
							Рестораторлар мен<br />
							сарапшыларға арналған чат
						</>
					),
					link: externalLinks.restoBusinessChat,
					svgBackdrop: (
						<BusinessMoneyFoodBackdrop
							className={classNames(
								styles.communityChat__backdrop,
								styles.__blueSkyLight
							)}
						/>
					),
					icon: (
						<StaticImage
							className={styles.communityChat__icon}
							src="./assets/BusinessMoneyFoodIcon.png"
							width={84}
							alt="test"
							objectFit="cover"
							placeholder="none"
							quality={90}
						/>
					),
				},
				{
					className: styles.__supportBot,
					title: "Quick Resto Бот",
					description: "Техникалық қолдау",
					link: externalLinks.supportBot,
					svgBackdrop: (
						<SupportBotBackdrop
							className={classNames(
								styles.communityChat__backdrop,
								styles.__purpleLight
							)}
						/>
					),
					icon: <SupportBotIcon className={styles.communityChat__icon}/>,
				},
			]
		}
	}

	return {
		communityChats: [
			{
				className: styles.__businessMoneyFood,
				title: "Бизнес, деньги, общепит",
				description: "Чат для рестораторов и экспертов",
				link: externalLinks.restoBusinessChat,
				svgBackdrop: (
					<BusinessMoneyFoodBackdrop
						className={classNames(
							styles.communityChat__backdrop,
							styles.__blueSkyLight
						)}
					/>
				),
				icon: (
					<StaticImage
						className={styles.communityChat__icon}
						src="./assets/BusinessMoneyFoodIcon.png"
						width={84}
						alt="test"
						objectFit="cover"
						placeholder="none"
						quality={90}
					/>
				),
			},
			{
				className: styles.__supportBot,
				title: "Quick Resto Бот",
				description: "Техподдержка",
				link: externalLinks.supportBot,
				svgBackdrop: (
					<SupportBotBackdrop
						className={classNames(
							styles.communityChat__backdrop,
							styles.__purpleLight
						)}
					/>
				),
				icon: <SupportBotIcon className={styles.communityChat__icon}/>,
			},
			{
				className: styles.__qrCommunityIcon,
				title: "Quick Resto Community",
				description: "Сообщество пользователей",
				link: externalLinks.communityChat,
				svgBackdrop: (
					<QRCommunityBackdrop
						className={classNames(
							styles.communityChat__backdrop,
							styles.__cyanLight
						)}
					/>
				),
				icon: <QRCommunityIcon className={styles.communityChat__icon} />,
			},

		],
	}
}
