import * as React from "react"

const SupportBotBackdrop = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 296 109"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path d="m295.531 57.527-1.494 15.25c-1.732 17.669-16.419 31.243-34.17 31.58l-216.025 4.096c-16.75.318-31.376-11.279-34.886-27.66L.867 43.046C-4.049 20.106 14.553-1.08 37.935.828L263.543 19.23c19.488 1.589 33.895 18.837 31.988 38.297Z" />
	</svg>
)

export default SupportBotBackdrop
