import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import mediaContent from "./mediaContent.module.scss";
import { pagesLinks } from "../../../pages-data/_V2/common/links";
import { Locales } from "../../../localization/types";

export const getData = (locale: Locales) => {
	if (locale === "ru-KZ") {
		return {
			features: [
				{
					tabName: "Учёт",
					desktopTitle: "Все цифры\nпод контролем",
					tabletTitle: "Все цифры под контролем",
					mobileTitle: "Все цифры \nпод контролем",
					desktopContent:
						"Веди управленческий учёт и быстро ориентируйся в показателях бизнеса. Порадуй бухгалтерию удобным расчётом фудкоста, сверками с поставщиками и полным документооборотом.",
					mobileContent: (
						<>
							Веди управленческий учёт, быстро ориентируйся в показателях&nbsp;бизнеса. Обрадуй бухгалтерию удобным
							расчётом фудкоста, сверками с поставщиками&nbsp;и&nbsp;полным документооборотом.
						</>
					),
					detailLink: pagesLinks.reports,
					mediaContent: (
						<StaticImage
							className={mediaContent.imageWrapper}
							src="./assets/accounting_kz.png"
							breakpoints={[291, 582, 831, 1662, 680, 1360]}
							sizes="(max-width: 500px) 291px, (max-width: 1319px) 831px,(min-width: 1320px) 680px"
							alt="Управленческий учет в ресторане"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
				{
					tabName: "Отчёты",
					desktopTitle: "Принимай решения\nна основе данных",
					desktopContent:
						"Следи за показателями заведения, чтобы найти баланс вкуса и прибыли. Скорректируй меню в пользу маржинальных и популярных позиций.",
					tabletContent:
						"Следи за показателями заведения. Найди баланс вкуса и прибыли. Скорректируй меню в пользу маржинальных и популярных позиций.",
					mobileContent: (
						<>
							Следи за&nbsp;показателями заведения.&nbsp;Найди баланс вкуса и&nbsp;прибыли. Скорректируй меню
							в&nbsp;пользу
							маржинальных и&nbsp;популярных позиций.
						</>
					),
					detailLink: pagesLinks.reports,
					mediaContent: (
						<StaticImage
							className={mediaContent.imageWrapper}
							src="./assets/reports-rounded_kz.png"
							breakpoints={[285, 570, 814, 1628, 678, 1356]}
							sizes="(max-width: 500px) 285px, (max-width: 1319px) 814px, (min-width: 1320px) 678px"
							alt="Отчеты в Quick Resto"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
				{
					tabName: "Уведомления",
					desktopTitle: "Не пропускай\nважные события",
					desktopContent:
						"Моментально оповестим о сомнительных операциях на кассе, открытии и закрытии смены, финансовых показателях рабочего дня.",
					mobileContent: (
						<>
							Моментально оповестим о&nbsp;сомнительных операциях на&nbsp;кассе, открытии и закрытии
							смены,&nbsp;финансовых показателях рабочего&nbsp;дня.
						</>
					),
					detailLink: pagesLinks.notifications,
					mediaContent: (
						<>
							<StaticImage
								className={mediaContent.notificationPhone}
								src="./assets/notification-phone.png"
								breakpoints={[285, 570, 602, 1204, 494, 988]}
								sizes="(max-width: 500px) 285px, (max-width: 1319px) 602px, (min-width: 1320px) 494px"
								alt="Уведомления о подозрительных операциях"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
							<StaticImage
								className={mediaContent.notificationAlerts}
								src="./assets/notification-alerts_kz.png"
								breakpoints={[285, 570, 602, 1204, 494, 988]}
								sizes="(max-width: 500px) 285px, (max-width: 1319px) 602px, (min-width: 1320px) 494px"
								alt="Уведомления о подозрительных операциях"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
						</>
					),
				},
				{
					tabName: "Персонал",
					desktopTitle: "Командная игра\nпо твоим правилам",
					desktopContent: (
						<p>
							Отслеживай отработанные часы и показатели <br />
							сотрудников,&nbsp;управляй правами доступа. <br />
							Используй статистику для гибкого расчёта зарплаты.
						</p>
					),
					tabletContent: "Отслеживай отработанные часы и следи за показателями сотрудников, управляй правами доступа. Используй статистику для гибкого расчёта зарплаты.",
					mobileContent: (
						<p>
							Отслеживай отработанные часы и&nbsp;следи за показателями сотрудников,&nbsp;управляй правами
							доступа.&nbsp;Используй статистику для&nbsp;гибкого расчёта зарплаты.
						</p>
					),
					detailLink: pagesLinks.staff,
					mediaContent: (
						<StaticImage
							className={mediaContent.imageWrapper}
							src="./assets/staff_kz.png"
							breakpoints={[285, 570, 814, 1628, 678, 1356]}
							sizes="(max-width: 500px) 285px, (max-width: 1319px) 814px, (min-width: 1320px) 678px"
							alt="Персонал в ресторане"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
			],
		};
	}

	if (locale === "kz-KZ") {
		return {
			features: [
				{
					tabName: "Есепке алу",
					desktopTitle: "Барлық цифрлар\nбақылауда",
					tabletTitle: "Барлық цифрлар бақылауда",
					mobileTitle: "Барлық цифрлар\nбақылауда",
					desktopContent:
						"Басқарушылық есепті жүргізіңіз және бизнес көрсеткіштеріне тез бейімделіңіз. Бухгалтерияныы фудкостты ыңғайлы есептеумен, жектізушілермен салыстырып тексерумен және толық құжат айналымымен қуантыңыз.",
					detailLink: pagesLinks.reports,
					mediaContent: (
						<StaticImage
							className={mediaContent.imageWrapper}
							src="./assets/accounting_kz.png"
							breakpoints={[291, 582, 831, 1662, 680, 1360]}
							sizes="(max-width: 500px) 291px, (max-width: 1319px) 831px,(min-width: 1320px) 680px"
							alt="Управленческий учет в ресторане"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
				{
					tabName: "Есептер",
					desktopTitle: "Деректер негізінде\nшешімдер қабылда",
					desktopContent:
						"Дәм мен пайда балансын табу үшін мекеменің көрсеткіштерін қадағалаңыз. Мәзірді маржиналдық және танымал позициялардың пайдасына түзетіңіз.",
					detailLink: pagesLinks.reports,
					mediaContent: (
						<StaticImage
							className={mediaContent.imageWrapper}
							src="./assets/reports-rounded_kz.png"
							breakpoints={[285, 570, 814, 1628, 678, 1356]}
							sizes="(max-width: 500px) 285px, (max-width: 1319px) 814px, (min-width: 1320px) 678px"
							alt="Отчеты в Quick Resto"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
				{
					tabName: "Хабарламалар",
					desktopTitle: "Маңызды оқиғаларды\nөткізіп алмаңыз",
					desktopContent:
						"Кассадағы күмәді операциялар, ауысымның ашылуы және жабылуы, жұмыс күнінің қаржылық көрсеткіштері туралы лезде хабарлаймыз.",
					detailLink: pagesLinks.notifications,
					mediaContent: (
						<>
							<StaticImage
								className={mediaContent.notificationPhone}
								src="./assets/notification-phone.png"
								breakpoints={[285, 570, 602, 1204, 494, 988]}
								sizes="(max-width: 500px) 285px, (max-width: 1319px) 602px, (min-width: 1320px) 494px"
								alt="Уведомления о подозрительных операциях"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
							<StaticImage
								className={mediaContent.notificationAlerts}
								src="./assets/notification-alerts_kz.png"
								breakpoints={[285, 570, 602, 1204, 494, 988]}
								sizes="(max-width: 500px) 285px, (max-width: 1319px) 602px, (min-width: 1320px) 494px"
								alt="Уведомления о подозрительных операциях"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
						</>
					),
				},
				{
					tabName: "Персонал",
					desktopTitle: "Сіздің ережелеріңіз\nбойынша командалық ойын",
					desktopContent: (
						<p>
							Отслеживай отработанные часы и показатели <br />
							сотрудников,&nbsp;управляй правами доступа. <br />
							Используй статистику для гибкого расчёта зарплаты.
						</p>
					),
					tabletContent: "Жұмыс істелген сағаттарды және қызметкерлердің көрсеткіштерін қадағалаңыз, қолжеткізу құқықтарын басқарыңыз. Жалақыны икемді есептеу үшін статистиканы пайдаланыңыз.",
					detailLink: pagesLinks.staff,
					mediaContent: (
						<StaticImage
							className={mediaContent.imageWrapper}
							src="./assets/staff_kz.png"
							breakpoints={[285, 570, 814, 1628, 678, 1356]}
							sizes="(max-width: 500px) 285px, (max-width: 1319px) 814px, (min-width: 1320px) 678px"
							alt="Персонал в ресторане"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
			],
		};
	}

	if (locale === "ru-BY") {
		return {
			features: [
				{
					tabName: "Учёт",
					desktopTitle: "Все цифры\nкак на ладони",
					desktopContent:
						"Веди управленческий учет и быстро ориентируйся в показателях бизнеса. Порадуй бухгалтерию удобным расчетом фудкоста, сверками с поставщиками и полным документооборотом.",
					detailLink: pagesLinks.reports,
					mediaContent: (
						<StaticImage
							className={mediaContent.imageWrapper}
							src="./assets/accounting_by.png"
							breakpoints={[291, 582, 831, 1662, 680, 1360]}
							sizes="(max-width: 500px) 291px, (max-width: 1319px) 831px,(min-width: 1320px) 680px"
							alt="Управленческий учет в ресторане"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
				{
					tabName: "Отчёты",
					desktopTitle: "Принимай решения\nна основе данных",
					desktopContent:
						"Следи за показателями заведения. Найди баланс вкуса и прибыли. Скорректируй меню в пользу маржинальных и популярных позиций.",
					detailLink: pagesLinks.reports,
					mediaContent: (
						<StaticImage
							className={mediaContent.imageWrapper}
							src="./assets/reports-rounded_by.png"
							breakpoints={[285, 570, 814, 1628, 678, 1356]}
							sizes="(max-width: 500px) 285px, (max-width: 1319px) 814px, (min-width: 1320px) 678px"
							alt="Отчеты в Quick Resto"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
				{
					tabName: "Уведомления",
					desktopTitle: "Фокус на ключевых событиях",
					desktopContent:
						"Моментально оповестим о сомнительных операциях \nна кассе, открытии и закрытии смены, финансовых показателях рабочего дня.",
					tabletContent:
						"Моментально оповестим о сомнительных операциях на кассе, открытии и закрытии смены, финансовых показателях рабочего дня.",
					detailLink: pagesLinks.notifications,
					mediaContent: (
						<>
							<StaticImage
								className={mediaContent.notificationPhone}
								src="./assets/notification-phone.png"
								breakpoints={[285, 570, 602, 1204, 494, 988]}
								sizes="(max-width: 500px) 285px, (max-width: 1319px) 602px, (min-width: 1320px) 494px"
								alt="Уведомления о подозрительных операциях"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
							<StaticImage
								className={mediaContent.notificationAlerts}
								src="./assets/notification-alerts_by.png"
								breakpoints={[285, 570, 602, 1204, 494, 988]}
								sizes="(max-width: 500px) 285px, (max-width: 1319px) 602px, (min-width: 1320px) 494px"
								alt="Уведомления о подозрительных операциях"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
						</>
					),
				},
				{
					tabName: "Персонал",
					desktopTitle: "Руководи людьми, думай о команде",
					desktopContent: (
						<p>
							Отслеживай отработанные часы и&nbsp;показатели <br />
							сотрудников, управляй правами доступа. <br />
							Используй статистику для гибкого расчёта зарплаты.
						</p>
					),
					tabletContent: (
						<p>
							Отслеживай отработанные часы и&nbsp;следи за&nbsp;показателями сотрудников, управляй правами доступа.
							Используй статистику для гибкого расчёта зарплаты.
						</p>
					),
					mobileContent: (
						<p>
							Отслеживай отработанные часы и&nbsp;показатели сотрудников, управляй правами доступа.
							Используй статистику для гибкого расчёта зарплаты.
						</p>
					),
					detailLink: pagesLinks.staff,
					mediaContent: (
						<StaticImage
							className={mediaContent.imageWrapper}
							src="./assets/staff_by.png"
							breakpoints={[285, 570, 814, 1628, 678, 1356]}
							sizes="(max-width: 500px) 285px, (max-width: 1319px) 814px, (min-width: 1320px) 678px"
							alt="Персонал в ресторане"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
			],
		};
	}

	return {
		features: [
			{
				tabName: "Учёт",
				desktopTitle: "Всё под\nконтролем",
				desktopContent:
					"Веди управленческий учет, быстро ориентируйся в показателях бизнеса. Обрадуй бухгалтерию удобным расчетом фудкоста, сверками с поставщиками и полным документооборотом.",
				detailLink: pagesLinks.reports,
				mediaContent: (
					<StaticImage
						className={mediaContent.imageWrapper}
						src="./assets/accounting.png"
						breakpoints={[291, 582, 831, 1662, 680, 1360]}
						sizes="(max-width: 500px) 291px, (max-width: 1319px) 831px,(min-width: 1320px) 680px"
						alt="Управленческий учет в ресторане"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
			{
				tabName: "Отчёты",
				desktopTitle: "Принимай правильные решения",
				desktopContent:
					"Следи за показателями заведения. Найди баланс вкуса и прибыли. Скорректируй меню в пользу маржинальных и популярных позиций.",
				detailLink: pagesLinks.reports,
				mediaContent: (
					<StaticImage
						className={mediaContent.imageWrapper}
						src="./assets/reports-rounded.png"
						breakpoints={[285, 570, 814, 1628, 678, 1356]}
						sizes="(max-width: 500px) 285px, (max-width: 1319px) 814px, (min-width: 1320px) 678px"
						alt="Отчеты в Quick Resto"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
			{
				tabName: "Уведомления",
				desktopTitle: "Фокус на ключевых событиях",
				desktopContent:
					"Моментально оповестим о сомнительных операциях на кассе, открытии и закрытии смены, финансовых показателях рабочего дня.",
				detailLink: pagesLinks.notifications,
				mediaContent: (
					<>
						<StaticImage
							className={mediaContent.notificationPhone}
							src="./assets/notification-phone.png"
							breakpoints={[285, 570, 602, 1204, 494, 988]}
							sizes="(max-width: 500px) 285px, (max-width: 1319px) 602px, (min-width: 1320px) 494px"
							alt="Уведомления о подозрительных операциях"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
						<StaticImage
							className={mediaContent.notificationAlerts}
							src="./assets/notification-alerts.png"
							breakpoints={[285, 570, 602, 1204, 494, 988]}
							sizes="(max-width: 500px) 285px, (max-width: 1319px) 602px, (min-width: 1320px) 494px"
							alt="Уведомления о подозрительных операциях"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					</>
				),
			},
			{
				tabName: "Персонал",
				desktopTitle: "Будь в команде, играй по правилам",
				desktopContent: (
					<p>
						Отслеживай отработанные часы и&nbsp;следи за&nbsp;показателями
						сотрудников, управляй правами доступа. Используй статистику для
						гибкого расчёта зарплаты.
					</p>
				),
				tabletContent: (
					<p>
						Отслеживай отработанные часы и&nbsp;следи за&nbsp;показателями
						сотрудников, управляй правами доступа. Используй статистику для
						гибкого расчёта зарплаты.
					</p>
				),
				mobileContent: (
					<p>
						Отслеживай отработанные часы и&nbsp;следи за&nbsp;показателями
						сотрудников, управляй правами доступа. Используй статистику для
						гибкого расчёта зарплаты.
					</p>
				),
				detailLink: pagesLinks.staff,
				mediaContent: (
					<StaticImage
						className={mediaContent.imageWrapper}
						src="./assets/staff.png"
						breakpoints={[285, 570, 814, 1628, 678, 1356]}
						sizes="(max-width: 500px) 285px, (max-width: 1319px) 814px, (min-width: 1320px) 678px"
						alt="Персонал в ресторане"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
		],
	};
};
