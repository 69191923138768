import * as React from "react"

const SupportBotIcon = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		width="42"
		height="42"
		viewBox="0 0 42 42"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<mask
			id="mask0_10007_213236"
			mask-type="alpha"
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
			width="42"
			height="42"
		>
			<circle cx="21" cy="21" r="21" fill="#D9D9D9" />
		</mask>
		<g mask="url(#mask0_10007_213236)">
			<rect width="42" height="42" fill="white" />
			<rect x="0.0117188" width="42" height="42" fill="#3021E5" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M29.872 6.61938C29.872 19.4519 19.4692 29.8547 6.63664 29.8547C4.43024 29.8547 2.29566 29.5472 0.273438 28.9726V-0.113281H28.8816C29.5257 2.01778 29.872 4.27814 29.872 6.61938Z"
				fill="#FC0B36"
			/>
			<path
				d="M9.05078 12.3037H11.8446V25.1712L11.6443 27.6846H9.05078V12.3037ZM17.4722 21.9067V22.6177C17.4722 23.4989 17.4021 24.2666 17.2619 24.9208C17.1284 25.575 16.9181 26.1225 16.6311 26.563C16.3507 27.0036 15.9902 27.3341 15.5496 27.5544C15.109 27.7747 14.5883 27.8848 13.9875 27.8848C13.4467 27.8848 12.9828 27.758 12.5956 27.5043C12.2084 27.244 11.8813 26.8868 11.6143 26.4329C11.3539 25.9722 11.1403 25.4349 10.9734 24.8207C10.8132 24.1998 10.6963 23.5223 10.6229 22.7879V21.7365C10.6897 21.0155 10.8032 20.3446 10.9634 19.7238C11.1303 19.1029 11.3439 18.5655 11.6042 18.1116C11.8646 17.651 12.1884 17.2938 12.5756 17.0401C12.9694 16.7798 13.4334 16.6496 13.9674 16.6496C14.5816 16.6496 15.109 16.7631 15.5496 16.9901C15.9902 17.2104 16.3507 17.5408 16.6311 17.9814C16.9181 18.422 17.1284 18.9728 17.2619 19.6337C17.4021 20.2879 17.4722 21.0456 17.4722 21.9067ZM14.6784 22.6177V21.9067C14.6784 21.4061 14.6584 20.9755 14.6183 20.615C14.5783 20.2545 14.5048 19.9541 14.398 19.7138C14.2912 19.4734 14.1377 19.2932 13.9374 19.173C13.7438 19.0529 13.4868 18.9928 13.1664 18.9928C12.8793 18.9928 12.639 19.0495 12.4454 19.163C12.2518 19.2698 12.0849 19.43 11.9447 19.6437C11.8112 19.8506 11.7144 20.0976 11.6543 20.3847C11.5942 20.6651 11.5575 20.9755 11.5442 21.3159V23.2085C11.5508 23.6691 11.6076 24.0763 11.7144 24.4302C11.8212 24.784 11.9914 25.0577 12.2251 25.2513C12.4654 25.4449 12.7858 25.5417 13.1864 25.5417C13.5001 25.5417 13.7538 25.4883 13.9474 25.3814C14.141 25.2746 14.2912 25.1044 14.398 24.8708C14.5115 24.6371 14.585 24.3367 14.6183 23.9695C14.6584 23.5957 14.6784 23.1451 14.6784 22.6177Z"
				fill="white"
			/>
			<path
				d="M18.8741 22.6377V21.9067C18.8741 21.0589 18.9742 20.3079 19.1745 19.6537C19.3815 18.9995 19.6752 18.452 20.0557 18.0115C20.4362 17.5642 20.8935 17.2271 21.4276 17.0001C21.9683 16.7664 22.5725 16.6496 23.24 16.6496C23.9143 16.6496 24.5184 16.7664 25.0525 17.0001C25.5932 17.2271 26.0538 17.5642 26.4344 18.0115C26.8215 18.452 27.1186 18.9995 27.3256 19.6537C27.5325 20.3079 27.636 21.0589 27.636 21.9067V22.6377C27.636 23.4789 27.5325 24.2265 27.3256 24.8808C27.1186 25.535 26.8215 26.0857 26.4344 26.533C26.0538 26.9803 25.5966 27.3174 25.0625 27.5444C24.5284 27.7714 23.9276 27.8848 23.2601 27.8848C22.5925 27.8848 21.9883 27.7714 21.4476 27.5444C20.9135 27.3174 20.4529 26.9803 20.0657 26.533C19.6785 26.0857 19.3815 25.535 19.1745 24.8808C18.9742 24.2265 18.8741 23.4789 18.8741 22.6377ZM21.6779 21.9067V22.6377C21.6779 23.1384 21.7113 23.5723 21.778 23.9395C21.8515 24.3067 21.9549 24.6104 22.0885 24.8507C22.2287 25.0911 22.3955 25.268 22.5891 25.3814C22.7827 25.4949 23.0064 25.5517 23.2601 25.5517C23.5137 25.5517 23.7407 25.4949 23.941 25.3814C24.1412 25.268 24.3048 25.0911 24.4316 24.8507C24.5652 24.6104 24.662 24.3067 24.722 23.9395C24.7888 23.5723 24.8222 23.1384 24.8222 22.6377V21.9067C24.8222 21.4194 24.7855 20.9922 24.712 20.625C24.6386 20.2512 24.5351 19.9474 24.4016 19.7138C24.2681 19.4734 24.1012 19.2932 23.9009 19.173C23.7073 19.0462 23.487 18.9828 23.24 18.9828C22.993 18.9828 22.7727 19.0462 22.5791 19.173C22.3922 19.2932 22.2287 19.4734 22.0885 19.7138C21.9549 19.9474 21.8515 20.2512 21.778 20.625C21.7113 20.9922 21.6779 21.4194 21.6779 21.9067Z"
				fill="white"
			/>
			<path
				d="M34.1348 16.8499V18.9627H28.6073V16.8499H34.1348ZM29.879 14.1763H32.6728V24.3801C32.6728 24.6805 32.7029 24.9108 32.7629 25.071C32.823 25.2312 32.9131 25.3447 33.0333 25.4115C33.1601 25.4716 33.3237 25.5016 33.524 25.5016C33.6708 25.5016 33.8044 25.4949 33.9245 25.4816C34.0447 25.4616 34.1348 25.4415 34.1949 25.4215V27.6145C33.9746 27.7013 33.7409 27.768 33.4939 27.8147C33.2536 27.8615 32.9665 27.8848 32.6328 27.8848C32.0787 27.8848 31.5947 27.778 31.1808 27.5644C30.7669 27.3508 30.4465 27.0103 30.2195 26.543C29.9925 26.069 29.879 25.4482 29.879 24.6805V14.1763Z"
				fill="white"
			/>
		</g>
	</svg>
)

export default SupportBotIcon
