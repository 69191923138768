import * as React from "react"

const ModifiersBackdropLeftIcon = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 288 321"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M102.585 160.994c1.954 1.129 2.642 3.644 1.297 5.456a35.546 35.546 0 0 1-19.352 13.156 35.566 35.566 0 0 1-26.985-3.553 35.56 35.56 0 0 1-14.851-44.93c.896-2.07 3.419-2.733 5.373-1.605l54.518 31.476ZM193.544 55.148c-.221 2.245 1.429 4.282 3.679 4.108a23.277 23.277 0 0 0 18.738-12.235 23.275 23.275 0 0 0 1.749-17.733 23.27 23.27 0 0 0-11.302-13.777 23.303 23.303 0 0 0-4.683-1.884c-2.173-.61-4.19 1.065-4.411 3.31l-3.77 38.21Z"
			fill="#E5EEFF"
		/>
		<path
			d="M190.054 56.164c1.91 1.202 4.47.636 5.32-1.455a23.275 23.275 0 0 0-2.572-22.23 23.286 23.286 0 0 0-36.237-2.187c-1.516 1.67-.919 4.224.991 5.426l32.498 20.446Z"
			fill="#E5EEFF"
		/>
		<path
			d="M182.037 76.688c3.45 12.877 27.756 62.06 40.69 58.594 12.933-3.466 9.391-58.212 5.941-71.089-3.451-12.876-16.686-20.518-29.563-17.068-12.877 3.45-20.518 16.686-17.068 29.563ZM210.945 160.72c2.179-.584 3.489-2.831 2.73-4.956a50.363 50.363 0 0 0-22.257-26.692 50.393 50.393 0 0 0-18.614-6.319 50.395 50.395 0 0 0-37.245 9.98 50.39 50.39 0 0 0-19.28 33.393 50.396 50.396 0 0 0 .389 15.631c.406 2.219 2.663 3.511 4.843 2.927l44.717-11.982 44.717-11.982Z"
			fill="#E5EEFF"
		/>
		<rect
			x={72.19}
			y={117.247}
			width={47.021}
			height={47.021}
			rx={4.085}
			transform="rotate(-60 72.19 117.247)"
			fill="#E5EEFF"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M257.161 201.73a106.674 106.674 0 0 0-1.741-37.573c-.5-2.2-2.748-3.492-4.928-2.908L52.282 214.36c-2.18.584-3.48 2.826-2.813 4.982a106.696 106.696 0 0 0 48.575 60.856 106.739 106.739 0 0 0 20.825 9.216 4.178 4.178 0 0 1 2.777 2.883l2.424 9.047a4.086 4.086 0 0 0 5.004 2.889l49.661-13.307.265-.07.264-.071 49.659-13.307a4.085 4.085 0 0 0 2.889-5.003l-2.424-9.046c-.368-1.372.009-2.833.964-3.885a106.678 106.678 0 0 0 26.809-57.814Z"
			fill="#E5EEFF"
		/>
		<circle
			cx={158.715}
			cy={86.447}
			r={10.076}
			transform="rotate(-15 158.715 86.447)"
			fill="#E5EEFF"
		/>
	</svg>
)

export default ModifiersBackdropLeftIcon
