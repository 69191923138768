import * as React from "react"
import { SVGProps } from "react"

const Backdrop = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width={1502}
		height={646}
		viewBox={"0 0 1502 646"}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1185.26 532.633 777.823 637.807c-73.58 18.994-151.807 2.018-210.896-45.764L89.104 205.651C25.667 154.352-5.306 76.66.744 0H1501.73c-2.05 25.147-8.19 50.613-18.92 75.633l-136.98 319.25c-29.31 68.302-88.61 119.173-160.57 137.75Z"
			fill="url(#a)"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1185.26 532.633 777.823 637.807c-73.58 18.994-151.807 2.018-210.896-45.764L89.104 205.651C25.667 154.352-5.306 76.66.744 0H1501.73c-2.05 25.147-8.19 50.613-18.92 75.633l-136.98 319.25c-29.31 68.302-88.61 119.173-160.57 137.75Z"
			fill="url(#b)"
			fillOpacity={0.2}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1185.26 532.633 777.823 637.807c-73.58 18.994-151.807 2.018-210.896-45.764L89.104 205.651C25.667 154.352-5.306 76.66.744 0H1501.73c-2.05 25.147-8.19 50.613-18.92 75.633l-136.98 319.25c-29.31 68.302-88.61 119.173-160.57 137.75Z"
			fill="url(#c)"
		/>
		<defs>
			<linearGradient
				id="a"
				x1={890.398}
				y1={-80.712}
				x2={762.481}
				y2={622.796}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#3A88FD" />
				<stop offset={0.458} stopColor="#977DFF" />
				<stop offset={1} stopColor="#FF84F3" />
			</linearGradient>
			<linearGradient
				id="c"
				x1={890.398}
				y1={-80.712}
				x2={762.481}
				y2={622.796}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#3A88FD" stopOpacity={0.7} />
				<stop offset={0.458} stopColor="#977DFF" stopOpacity={0.4} />
				<stop offset={1} stopColor="#FF84F3" stopOpacity={0.2} />
			</linearGradient>
			<pattern
				id="b"
				patternContentUnits="objectBoundingBox"
				width={0.013}
				height={0.032}
			>
				<use xlinkHref="#d" transform="scale(.00033 .00077)" />
			</pattern>
			<image
				id="d"
				width={40}
				height={41}
				xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAApCAYAAABHomvIAAAAf0lEQVRYCe3SwQnAIBBE0ek1ahuxg9iLbFKWJSRIPAi5eBIhX1jw4OL4XImFAAIIIIAAAggggAACiwgEXbuTlVpB57FIrDdGDeRld19BFpcJ6WWlD9f2hYCjAk4Wv4I5jfZPObcpp/bVxSmvM39TXs8lCCCAAAIIIIAAAr8UeAAiHz7ihAEDdwAAAABJRU5ErkJggg=="
			/>
		</defs>
	</svg>
)

export default Backdrop
