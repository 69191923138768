import { Locales } from "../../../localization/types";
import ogImage from "../ProductPresentation/assets/pres.png";
import ogImageKz from "../ProductPresentation/assets/pres_kz.png";
import ogImageBy from "../ProductPresentation/assets/pres_by.png";
import {externalLinks} from "../../../pages-data/_V2/common/links";

export const getIndexMeta = (locale: Locales) => {
	if (locale === 'ru-KZ') {
		return {
			title: 'Автоматизация ресторанов и ресторанного бизнеса — программа складского учёта для общепита с кассой на планшете — Quick Resto Казахстан',
			metaTags: [
				{
					name: 'description',
					content:
						'Quick Resto — автоматизация ресторана, кафе, бара, кофейни или столовой под ключ. Программа для полноценного складского учёта в общепите онлайн, удобный кассовый терминал на планшете, CRM и настраиваемая система отчетов. Автоматизируй бизнес в Казахстане',
				},
				{
					property: 'og:url',
					content: externalLinks.quickrestoKZ.href,
				},
				{
					property: 'og:title',
					content: 'Автоматизация ресторанов и ресторанного бизнеса — программа складского учёта для общепита с кассой на планшете — Quick Resto Казахстан',
				},
				{
					property: 'og:description',
					content:
						'Quick Resto — автоматизация ресторана, кафе, бара, кофейни или столовой под ключ. Программа для полноценного складского учёта в общепите онлайн, удобный кассовый терминал на планшете, CRM и настраиваемая система отчетов. Автоматизируй бизнес в Казахстане',
				},
				{
					property: 'og:type',
					content: 'website',
				},
				{
					property: 'og:image',
					content: `${externalLinks.quickrestoKZ.href}${ogImageKz}`,
				},
			],
			canonical: externalLinks.quickrestoKZ.href,
		}
	}

	if (locale === 'ru-BY') {
		return {
			title: 'Автоматизация ресторанов и ресторанного бизнеса — программа складского учёта для общепита с кассой на планшете — Quick Resto Беларусь',
			metaTags: [
				{
					name: 'description',
					content:
						'Quick Resto — автоматизация ресторана, кафе, бара, кофейни или столовой под ключ. Программа для полноценного складского учёта в общепите онлайн, удобный кассовый терминал на планшете, CRM и настраиваемая система отчетов. Автоматизируй бизнес в Беларуси',
				},
				{
					property: 'og:url',
					content: externalLinks.quickrestoBY.href,
				},
				{
					property: 'og:title',
					content: 'Автоматизация ресторанов и ресторанного бизнеса — программа складского учёта для общепита с кассой на планшете — Quick Resto Беларусь',
				},
				{
					property: 'og:description',
					content:
						'Quick Resto — автоматизация ресторана, кафе, бара, кофейни или столовой под ключ. Программа для полноценного складского учёта в общепите онлайн, удобный кассовый терминал на планшете, CRM и настраиваемая система отчетов. Автоматизируй бизнес в Беларуси',
				},
				{
					property: 'og:type',
					content: 'website',
				},
				{
					property: 'og:image',
					content: `${externalLinks.quickrestoBY.href}${ogImageBy}`,
				},
			],
			canonical: externalLinks.quickrestoBY.href,
		}
	}

	return {
		title: 'Программы для общепита — программное обеспечение для ресторанов и кафе | Quick Resto',
		metaTags: [
			{
				name: 'description',
				content:
					'Quick Resto – автоматизация ресторана, кафе, бара, кофейни или столовой под ключ. Программа для полноценного складского учёта в общепите онлайн, удобный кассовый терминал на планшете, CRM и настраиваемая система отчетов.',
			},
			{
				property: 'og:url',
				content: externalLinks.quickrestoRU.href,
			},
			{
				property: 'og:title',
				content: 'Программы для общепита — программное обеспечение для ресторанов и кафе | Quick Resto',
			},
			{
				property: 'og:description',
				content:
					'Quick Resto – автоматизация ресторана, кафе, бара, кофейни или столовой под ключ. Программа для полноценного складского учёта в общепите онлайн, удобный кассовый терминал на планшете, CRM и настраиваемая система отчетов.',
			},
			{
				property: 'og:type',
				content: 'website',
			},
			{
				property: 'og:image',
				content: `${externalLinks.quickrestoRU.href}${ogImage}`,
			},
		],
		canonical: externalLinks.quickrestoRU.href,
	}
}
