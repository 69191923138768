import React from "react"
import classNames from "classnames"

import styles from "./styles.module.scss"

import { getData } from "./data"
import FeaturesGallery from "../../../components/_V2/FeaturesGallery"
import {useLocalizationContext} from "../../../localization/useLocalizationContext";

interface Props {
	className?: string
}

export default function BackofficeFeatures(props: Props) {
	const localizationContext = useLocalizationContext();

	return (
		<section className={classNames(styles.section, props.className)}>
			<FeaturesGallery
				key={localizationContext.locale}
				className={styles.featuresGallery}
				theme={"purple"}
				features={getData(localizationContext.locale).features}
			/>
		</section>
	)
}
