import * as React from "react"
import { SVGProps } from "react"

const ModifiersBackdropRightIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 396 450"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M352.465 223.089c1.98-.53 4.019.644 4.502 2.636a153.494 153.494 0 0 1-55.74 157.908 153.5 153.5 0 0 1-170.19 11.154 153.494 153.494 0 0 1-70.514-89.63c-.578-1.966.601-4.003 2.581-4.534l289.361-77.534ZM175.664 253.833c1.98-.531 3.168-2.572 2.493-4.507a50.193 50.193 0 0 0-60.371-31.94 50.19 50.19 0 0 0-36.313 57.846c.383 2.014 2.433 3.187 4.413 2.657l22.64-6.067c1.98-.531 3.117-2.571 2.974-4.616a19.316 19.316 0 0 1 14.274-20.009 19.322 19.322 0 0 1 22.366 10.192c.899 1.842 2.903 3.041 4.883 2.51l22.641-6.066Z"
			fill="#E5EEFF"
		/>
		<circle
			cx={280.036}
			cy={103.417}
			r={23.195}
			transform="rotate(-15 280.036 103.417)"
			fill="#E5EEFF"
		/>
		<circle
			cx={156.831}
			cy={184.394}
			r={14.497}
			transform="rotate(-15 156.831 184.394)"
			fill="#E5EEFF"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M95.232 124.905a42.615 42.615 0 0 1 12.462 3.634c1.857.866 2.46 3.152 1.435 4.928l-6.207 10.75a3.712 3.712 0 0 0 1.841 5.304l26.233 10.452a3.712 3.712 0 0 1 1.84 5.304l-23.229 40.233a3.71 3.71 0 0 1-5.513 1.058l-22.169-17.492a3.712 3.712 0 0 0-5.513 1.058l-6.208 10.752c-1.024 1.775-3.306 2.396-4.985 1.221a42.647 42.647 0 0 1-16.736-23.896 42.636 42.636 0 0 1 46.749-53.306ZM327.782 210.693c-.074 1.526-1.173 2.784-2.649 3.179l-123.581 33.114c-.99.265-2.051.116-2.9-.458-9.869-6.683-16.674-17.675-17.556-30.488-1.321-19.19 11.08-36.157 28.836-41.295a4.207 4.207 0 0 0 2.948-3.211c3.448-17.324 18.134-30.901 36.603-32.172 18.468-1.271 34.876 10.165 40.665 26.854a4.204 4.204 0 0 0 3.36 2.776c18.294 2.657 32.903 17.764 34.224 36.956.11 1.599.125 3.183.05 4.745Z"
			fill="#E5EEFF"
		/>
		<path
			d="M219.037 113.257c-2.589 22.487-22.918 38.618-45.406 36.029-22.487-2.59-38.618-22.919-36.029-45.406 2.59-22.488 25.513-61.148 48-58.559 22.488 2.59 36.024 45.448 33.435 67.936Z"
			fill="#E5EEFF"
		/>
	</svg>
)

export default ModifiersBackdropRightIcon
