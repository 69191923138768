import * as React from "react"

const Coaching = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		width={32}
		height={32}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="m11.065 22.412-1.745 6.51a1.655 1.655 0 0 0 .17 1.22c.104.182.243.341.407.468.165.127.352.218.55.27l.118.031c.38.1.78.047 1.125-.144.344-.192.606-.51.734-.89l2.758-8.25M17.757 13.316h5.467c.444 0 .874-.152 1.224-.433.35-.28.6-.672.709-1.115l.92-3.735a1.653 1.653 0 0 0-.17-1.218 1.592 1.592 0 0 0-.408-.466 1.541 1.541 0 0 0-1.158-.303c-.203.03-.398.101-.574.209-.176.108-.33.25-.453.42-.123.169-.212.361-.262.566l-.706 2.859h-3.788"
			stroke="#3021E5"
			strokeWidth={2}
			strokeMiterlimit={10}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="m16.502 17.617 1.853-7.517H9.526c-.443 0-.874.152-1.224.432s-.599.673-.708 1.116l-.956 3.874c-.102.413-.04.851.172 1.218.211.367.556.632.957.737a1.517 1.517 0 0 0 1.182-.177c.177-.108.33-.25.453-.42.123-.169.212-.361.262-.566l.74-2.998h2.791l-.963 3.902a3.422 3.422 0 0 0-.034 1.501c.1.497.31.963.61 1.364.303.4.69.724 1.131.947a3.15 3.15 0 0 0 1.42.339h3.419l-.961 3.896a1.653 1.653 0 0 0 .171 1.218c.105.182.244.34.408.467a1.54 1.54 0 0 0 1.158.303c.203-.03.398-.102.574-.21.177-.108.33-.25.453-.42.123-.169.212-.361.262-.566l1.335-5.332a2.5 2.5 0 0 0-2.425-3.108h-3.25Z"
			stroke="#3021E5"
			strokeWidth={2}
			strokeMiterlimit={10}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M17.366 6.96a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"
			stroke="#3021E5"
			strokeWidth={2}
			strokeMiterlimit={10}
			strokeLinejoin="round"
		/>
	</svg>
)

export default Coaching
