import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { pagesLinks } from "../../../pages-data/_V2/common/links";
import CrmBackdropIcon from "./assets/CrmBackdropIcon";
import DeliveryBackdropIcon from "./assets/DeliveryBackdropIcon";
import DeliveryClubIcon from "./assets/DeliveryClubIcon";
import LineToDeliveryClub from "./assets/LineToDeliveryClub";
import LineToMobile from "./assets/LineToMobile";
import LineToShop from "./assets/LineToShop";
import LineToYandexEda from "./assets/LineToYandexEda";
import MobileIcon from "./assets/MobileIcon";
import ModifiersBackdropLeftIcon from "./assets/ModifiersBackdropLeftIcon";
import ModifiersBackdropRightIcon from "./assets/ModifiersBackdropRightIcon";
import SiteIcon from "./assets/SiteIcon";
import TableMapBackdropIcon from "./assets/TableMapBackdropIcon";
import YandexEdaIcon from "./assets/YandexEdaIcon";
import backdropIconsStyles from "./backdropIconsStyles.module.scss";
import mediaContentStyles from "./mediaContentStyles.module.scss";
import classNames from "classnames";
import { Locales } from "../../../localization/types";
import img from "./assets/delivery-feature_tablet_kz.png";
import imgMob from "./assets/delivery-feature_mob_kz.png";

export const getData = (locale: Locales) => {
	if (locale === "ru-KZ") {
		return {
			features: [
				{
					tabName: "Доставка",
					desktopTitle: "Доставляй в два клика",
					mobileTitle: "Доставляй в два клика",
					desktopContent:
						"Создай приложение и сайт с возможностью доставки. Подключи основные сервисы или организуй собственных курьеров. Обрабатывай заказы на доставку с того же терминала, что стоит в зале.\nУчёт и аналитика формируются автоматически.",
					detailLink: pagesLinks.delivery,
					mediaContent: (
						<>
							<StaticImage
								className={mediaContentStyles.deliveryImage_kz}
								src="./assets/delivery-feature_kz.png"
								breakpoints={[300, 600, 808, 2616, 817, 2634]}
								sizes={
									"(max-width: 500px) 300px, (max-width: 1319px) 808px, (min-width: 1320px) 1817px"
								}
								alt={"Доставка для ресторанного бизнеса"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={100}
							/>

							<img
								className={mediaContentStyles.deliveryImage_tablet_kz}
								src={img}
								alt={"Доставка для ресторанного бизнеса"}
							/>

							<img
								className={mediaContentStyles.deliveryImage_mob_kz}
								src={imgMob}
								alt={"Доставка для ресторанного бизнеса"}
							/>
						</>
					),
					backdropContent: (
						<>
							<DeliveryBackdropIcon
								className={backdropIconsStyles.deliveryBackdropIconLeft}
							/>

							<DeliveryBackdropIcon
								className={backdropIconsStyles.deliveryBackdropIconRight}
							/>
						</>
					),
				},
				{
					tabName: "Карта столов",
					desktopTitle: "Карты, деньги, два стола",
					desktopContent:
						"Схема столов на терминале — удобный способ ориентироваться в заказах. Официанты не забудут про гостей,\nа если те решили пересесть, то смогут без проблем перенести заказы на схеме рассадки. \nСчёт привязан к столу, но при необходимости его можно разделить или объединить.",
					tabletContent:
						"Схема столов на терминале — удобный способ ориентироваться в заказах. Официанты не забудут про гостей,\nа если те решили пересесть, то смогут без проблем перенести заказы на схеме рассадки. Счёт привязан к столу, но при необходимости его можно разделить или объединить.",
					detailLink: pagesLinks.terminal,
					mediaContent: (
						<StaticImage
							className={classNames(
								mediaContentStyles.centeredInterfaceImage,
								mediaContentStyles.tablesMapImage,
							)}
							src="./assets/map-feature_kz.png"
							breakpoints={[277, 554, 808, 1616, 770, 1540]}
							sizes={
								"(max-width: 500px) 277px, (max-width: 1319px) 808px, (min-width: 1320px) 770px"
							}
							alt={"Карта столов для ресторана"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: (
						<>
							<TableMapBackdropIcon
								className={backdropIconsStyles.tableMapBackdropIconLeft}
							/>

							<TableMapBackdropIcon
								className={backdropIconsStyles.tableMapBackdropIconRight}
							/>
						</>
					),
				},
				{
					tabName: "Модификаторы",
					desktopTitle: "Исполнение желаний каждого гостя",
					desktopContent:
						"Настрой блюдо по запросам гостя прямо на терминале. Каша на овсяном молоке или окрошка на кефире —\nтеперь не надо бегать на кухню и передавать пожелания повару. Всё автоматически отражается в заказе.",
					detailLink: pagesLinks.terminal,
					mediaContent: (
						<StaticImage
							className={classNames(
								mediaContentStyles.centeredInterfaceImage,
								mediaContentStyles.centeredInterfaceImageSm,
							)}
							src="./assets/modifiers-feature_kz.png"
							breakpoints={[277, 554, 808, 1616, 770, 1540]}
							sizes={
								"(max-width: 500px) 277px, (max-width: 1319px) 808px, (min-width: 1320px) 770px"
							}
							alt={"Модификаторы блюд в Quick Resto"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: (
						<>
							<ModifiersBackdropLeftIcon
								className={backdropIconsStyles.modifiersBackdropLeftIcon}
							/>

							<ModifiersBackdropRightIcon
								className={backdropIconsStyles.modifiersBackdropRightIcon}
							/>
						</>
					),
				},
				{
					tabName: "Акции и бонусы",
					desktopTitle: "Посмотри, кто к нам пришёл!",
					tabletTitle: "Посмотри, кто к нам пришёл!",
					mobileTitle: "Посмотри, кто \nк нам пришёл!",
					desktopContent:
						"Встречай гостя так, как будто давно его ждешь. Предлагай акции, учитывай бонусные баллы\nи сканируй карты лояльности прямо на терминале.",
					detailLink: pagesLinks.marketing,
					mediaContent: (
						<StaticImage
							className={classNames(
								mediaContentStyles.centeredInterfaceImage,
								mediaContentStyles.centeredInterfaceImageSm,
							)}
							src="./assets/discounts-feature_kz.png"
							breakpoints={[277, 554, 808, 1616, 770, 1540]}
							sizes={
								"(max-width: 500px) 277px, (max-width: 1319px) 808px, (min-width: 1320px) 770px"
							}
							alt={"Бонусные программы для кафе"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: (
						<>
							<CrmBackdropIcon
								className={backdropIconsStyles.crmBackdropIconLeft}
							/>

							<CrmBackdropIcon
								className={backdropIconsStyles.crmBackdropIconRight}
							/>
						</>
					),
				},
			] as Array<{
				tabName: string
				// Заголовки могут быть для разных раскладок
				desktopTitle: string
				tabletTitle?: string
				mobileTitle?: string

				// Текстовый контент может быть для разных
				desktopContent: string
				tabletContent?: string
				mobileContent?: string

				detailLink?: { href: string; useGatsbyLink: boolean; text?: string }

				mediaContent: JSX.Element
				backdropContent: JSX.Element
			}>,
		};
	}

	if (locale === "kz-KZ") {
		return {
			features: [
				{
					tabName: "Жеткізу",
					desktopTitle: "Екі клик арқылы жеткізіңіз",
					mobileTitle: "Екі клик арқылы жеткізіңіз",
					desktopContent:
						"Жеткізу мүмкіндігі бар қосымшан және сайтты жасаңыз. Негізгі сервистерді қосыңыз немесе өз курьерлеріңізді ұйымдастырыңыз. Залда тұрған терминалдан-ақ жеткізуге тапсырыстарды өңдеңіз. Есепке алу және аналитика автоматты түрде қалыптасады.",
					detailLink: pagesLinks.delivery,
					mediaContent: (
						<>
							<StaticImage
								className={mediaContentStyles.deliveryImage_kz}
								src="./assets/delivery-feature_kz.png"
								breakpoints={[300, 600, 808, 2616, 817, 2634]}
								sizes={
									"(max-width: 500px) 300px, (max-width: 1319px) 808px, (min-width: 1320px) 1817px"
								}
								alt={"Доставка для ресторанного бизнеса"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={100}
							/>

							<img
								className={mediaContentStyles.deliveryImage_tablet_kz}
								src={img}
								alt={"Доставка для ресторанного бизнеса"}
							/>

							<img
								className={mediaContentStyles.deliveryImage_mob_kz}
								src={imgMob}
								alt={"Доставка для ресторанного бизнеса"}
							/>
						</>
					),
					backdropContent: (
						<>
							<DeliveryBackdropIcon
								className={backdropIconsStyles.deliveryBackdropIconLeft}
							/>

							<DeliveryBackdropIcon
								className={backdropIconsStyles.deliveryBackdropIconRight}
							/>
						</>
					),
				},
				{
					tabName: "Үстелдер картасы",
					desktopTitle: "Карталар, ақша, екі үстел",
					desktopContent:
						"Терминалдағы үстелдер сұлбасы — тапсырыстарды білудің ыңғайлы тәсілі. Даяшылар қонақтар туралы ұмытпайды, ал егер олар орнын ауыстыруды шешсе — еш қиындықсыз тапсырыстарды отырғызу сұлбасында ауыстыра алады. Шот үстелге байластырылған, бірақ қажет болған кезде оны бөлуге немесе біріктіруге болады.",
					detailLink: pagesLinks.terminal,
					mediaContent: (
						<StaticImage
							className={classNames(
								mediaContentStyles.centeredInterfaceImage,
								mediaContentStyles.tablesMapImage,
							)}
							src="./assets/map-feature_kz.png"
							breakpoints={[277, 554, 808, 1616, 770, 1540]}
							sizes={
								"(max-width: 500px) 277px, (max-width: 1319px) 808px, (min-width: 1320px) 770px"
							}
							alt={"Карта столов для ресторана"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: (
						<>
							<TableMapBackdropIcon
								className={backdropIconsStyles.tableMapBackdropIconLeft}
							/>

							<TableMapBackdropIcon
								className={backdropIconsStyles.tableMapBackdropIconRight}
							/>
						</>
					),
				},
				{
					tabName: "Модификаторлар",
					desktopTitle: "Әр қонақтың тілегін орындау",
					desktopContent:
						"Тағамды қонақтың сұрауы бойынша тікелей терминалда теңшеңіз. Сұлы сүтіндегі ботқа немесе айрандағы окрошка — енді асүйге жүгіріп, аспазға тілектерін жеткізудің керегі жоқ. Барлығы тапсырыста автоматты түрде бейнеленеді.",
					detailLink: pagesLinks.terminal,
					mediaContent: (
						<StaticImage
							className={classNames(
								mediaContentStyles.centeredInterfaceImage,
								mediaContentStyles.centeredInterfaceImageSm,
							)}
							src="./assets/modifiers-feature_kz.png"
							breakpoints={[277, 554, 808, 1616, 770, 1540]}
							sizes={
								"(max-width: 500px) 277px, (max-width: 1319px) 808px, (min-width: 1320px) 770px"
							}
							alt={"Модификаторы блюд в Quick Resto"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: (
						<>
							<ModifiersBackdropLeftIcon
								className={backdropIconsStyles.modifiersBackdropLeftIcon}
							/>

							<ModifiersBackdropRightIcon
								className={backdropIconsStyles.modifiersBackdropRightIcon}
							/>
						</>
					),
				},
				{
					tabName: "Акциялар мен бонустар",
					desktopTitle: "Бізге кімнің келгенін қараңыз!",
					desktopContent:
						"Қонақты бұрыннан күтіп тұрғандай қарсы алыңыз. Акцияларды ұсыныңыз, бонустық баллдарды есепке алыңыз, адалдық карталарын тікелей терминалда сканерлеңіз.",
					detailLink: pagesLinks.marketing,
					mediaContent: (
						<StaticImage
							className={classNames(
								mediaContentStyles.centeredInterfaceImage,
								mediaContentStyles.centeredInterfaceImageSm,
							)}
							src="./assets/discounts-feature_kz.png"
							breakpoints={[277, 554, 808, 1616, 770, 1540]}
							sizes={
								"(max-width: 500px) 277px, (max-width: 1319px) 808px, (min-width: 1320px) 770px"
							}
							alt={"Бонусные программы для кафе"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: (
						<>
							<CrmBackdropIcon
								className={backdropIconsStyles.crmBackdropIconLeft}
							/>

							<CrmBackdropIcon
								className={backdropIconsStyles.crmBackdropIconRight}
							/>
						</>
					),
				},
			] as Array<{
				tabName: string
				// Заголовки могут быть для разных раскладок
				desktopTitle: string
				tabletTitle?: string
				mobileTitle?: string

				// Текстовый контент может быть для разных
				desktopContent: string
				tabletContent?: string
				mobileContent?: string

				detailLink?: { href: string; useGatsbyLink: boolean; text?: string }

				mediaContent: JSX.Element
				backdropContent: JSX.Element
			}>,
		};
	}

	if (locale === "ru-BY") {
		return {
			features: [
				{
					tabName: "Доставка",
					desktopTitle: "Все службы доставки – в одном месте",
					mobileTitle: "Все службы доставки – в одном месте",
					detailLink: pagesLinks.delivery,
					desktopContent:
						"Создай приложение и сайт с возможностью доставки – быстро и бесплатно. Подключи основные сервисы\nили организуй собственных курьеров. Обрабатывай заказы доставки с того же терминала, что стоит в зале.\nУчет и аналитика формируется автоматически.",
					tabletContent: (
						<>
							Создай приложение и&nbsp;сайт с&nbsp;возможностью доставки&nbsp;&mdash; быстро и&nbsp;бесплатно. Подключи
							основные сервисы или&nbsp;организуй собственных курьеров. Обрабатывай заказы доставки с&nbsp;того&nbsp;же
							терминала, что стоит в&nbsp;зале. Учет и&nbsp;аналитика формируется автоматически.
						</>
					),
					mediaContent: (
						<>
							<StaticImage
								className={classNames(mediaContentStyles.deliveryImage_kz, mediaContentStyles.deliveryImage_by)}
								src="./assets/delivery-feature_by_new.png"
								breakpoints={[300, 600, 808, 1616, 817, 1634]}
								sizes={
									"(max-width: 500px) 300px, (max-width: 1319px) 808px, (min-width: 1320px) 1817px"
								}
								alt={"Доставка для ресторанного бизнеса"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={100}
							/>

							<StaticImage
								className={mediaContentStyles.deliveryImage_tablet_kz}
								src="./assets/delivery-feature_tablet_by.png"
								breakpoints={[300, 600, 808, 1616, 817, 1634]}
								sizes={
									"(max-width: 500px) 300px, (max-width: 1319px) 808px, (min-width: 1320px) 817px"
								}
								alt={"Доставка для ресторанного бизнеса"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>

							<StaticImage
								className={mediaContentStyles.deliveryImage_mob_kz}
								src="./assets/delivery-feature_mob_by.png"
								breakpoints={[300, 600, 808, 1616, 817, 1634]}
								sizes={
									"(max-width: 500px) 300px, (max-width: 1319px) 808px, (min-width: 1320px) 817px"
								}
								alt={"Доставка для ресторанного бизнеса"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>

							{/*<div className={mediaContentStyles.deliveryTilesContainer}>*/}
							{/*	<div className={mediaContentStyles.deliveryClubTile}>*/}
							{/*		<DeliveryClubIcon*/}
							{/*			className={mediaContentStyles.deliveryClubTile__icon}*/}
							{/*		/>*/}
							{/*	</div>*/}

							{/*	<div className={mediaContentStyles.yandexEdaTile}>*/}
							{/*		<YandexEdaIcon*/}
							{/*			className={mediaContentStyles.yandexEdaTile__icon}*/}
							{/*		/>*/}
							{/*	</div>*/}
							{/*	<div className={mediaContentStyles.siteTile}>*/}
							{/*		<SiteIcon className={mediaContentStyles.siteTile__icon} />*/}
							{/*	</div>*/}
							{/*	<div className={mediaContentStyles.mobileTile}>*/}
							{/*		<MobileIcon className={mediaContentStyles.mobileTile__icon} />*/}
							{/*	</div>*/}
							{/*</div>*/}

							{/*<LineToDeliveryClub*/}
							{/*	className={mediaContentStyles.lineToDeliveryClub}*/}
							{/*/>*/}

							{/*<LineToYandexEda className={mediaContentStyles.lineToYandexEda} />*/}

							{/*<LineToShop className={mediaContentStyles.lineToShop} />*/}

							{/*<LineToMobile className={mediaContentStyles.lineToMobile} />*/}
						</>
					),
					backdropContent: (
						<>
							<DeliveryBackdropIcon
								className={backdropIconsStyles.deliveryBackdropIconLeft}
							/>

							<DeliveryBackdropIcon
								className={backdropIconsStyles.deliveryBackdropIconRight}
							/>
						</>
					),
				},
				{
					tabName: "Карта столов",
					desktopTitle: "Карты, деньги, два стола",
					desktopContent:
						"Схема столов на терминале — удобный способ ориентироваться в заказах. Официанты не забудут про гостей,\nа если те решили пересесть, то смогут без проблем перенести заказы на схеме рассадки. Счёт привязан к\nстолу, но при необходимости его можно разделить или объединить.",
					tabletContent: (
						<span className={mediaContentStyles.tabletWidth}>
							Схема столов на&nbsp;терминале&nbsp;&mdash; удобный способ ориентироваться в&nbsp;заказах. Официанты
							не&nbsp;забудут про гостей, а если те&nbsp;решили пересесть&nbsp;&mdash; смогут без проблем перенести
							заказы на&nbsp;схеме рассадки. Счёт привязан к&nbsp;столу, но&nbsp;при необходимости его можно разделить
							или объединить.
						</span>
					),
					detailLink: pagesLinks.terminal,
					mediaContent: (
						<StaticImage
							className={classNames(
								mediaContentStyles.centeredInterfaceImage,
								mediaContentStyles.tablesMapImage,
							)}
							src="./assets/map-feature_by.png"
							breakpoints={[277, 554, 808, 1616, 770, 1540]}
							sizes={
								"(max-width: 500px) 277px, (max-width: 1319px) 808px, (min-width: 1320px) 770px"
							}
							alt={"Карта столов для ресторана"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: (
						<>
							<TableMapBackdropIcon
								className={backdropIconsStyles.tableMapBackdropIconLeft}
							/>

							<TableMapBackdropIcon
								className={backdropIconsStyles.tableMapBackdropIconRight}
							/>
						</>
					),
				},
				{
					tabName: "Модификаторы",
					desktopTitle: "Любое желание гостя будет исполнено",
					desktopContent:
						"Настрой блюдо по запросам гостя прямо на терминале. Каша на овсяном молоке или окрошка на кефире \n— теперь не надо бегать на кухню и передавать пожелания повару. Всё автоматически отражается в заказе.",
					tabletContent: (
						<span className={mediaContentStyles.tabletWidth}>
							Настрой блюдо по&nbsp;запросам гостя прямо на&nbsp;терминале. Каша на&nbsp;овсяном молоке, окрошка на&nbsp;кефире&nbsp;&mdash; теперь не надо&nbsp;бегать на&nbsp;кухню и&nbsp;передавать лично повару. Все автоматически отражается в&nbsp;заказе.
						</span>
					),
					detailLink: pagesLinks.terminal,
					mediaContent: (
						<StaticImage
							className={classNames(
								mediaContentStyles.centeredInterfaceImage,
								mediaContentStyles.centeredInterfaceImageSm,
							)}
							src="./assets/modifiers-feature_by.png"
							breakpoints={[277, 554, 808, 1616, 770, 1540]}
							sizes={
								"(max-width: 500px) 277px, (max-width: 1319px) 808px, (min-width: 1320px) 770px"
							}
							alt={"Модификаторы блюд в Quick Resto"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: (
						<>
							<ModifiersBackdropLeftIcon
								className={backdropIconsStyles.modifiersBackdropLeftIcon}
							/>

							<ModifiersBackdropRightIcon
								className={backdropIconsStyles.modifiersBackdropRightIcon}
							/>
						</>
					),
				},
				{
					tabName: "Акции и бонусы",
					desktopTitle: "Посмотри, кто к нам пришёл!",
					desktopContent:
						"Встречай гостя так, как будто давно его ждешь. Предлагай акции, учитывай бонусные баллы\nи сканируй карты лояльности прямо на терминале.",
					detailLink: pagesLinks.marketing,
					mediaContent: (
						<StaticImage
							className={classNames(
								mediaContentStyles.centeredInterfaceImage,
								mediaContentStyles.centeredInterfaceImageSm,
							)}
							src="./assets/discounts-feature_by.png"
							breakpoints={[277, 554, 808, 1616, 770, 1540]}
							sizes={
								"(max-width: 500px) 277px, (max-width: 1319px) 808px, (min-width: 1320px) 770px"
							}
							alt={"Бонусные программы для кафе"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: (
						<>
							<CrmBackdropIcon
								className={backdropIconsStyles.crmBackdropIconLeft}
							/>

							<CrmBackdropIcon
								className={backdropIconsStyles.crmBackdropIconRight}
							/>
						</>
					),
				},
			] as Array<{
				tabName: string
				// Заголовки могут быть для разных раскладок
				desktopTitle: string
				tabletTitle?: string
				mobileTitle?: string

				// Текстовый контент может быть для разных
				desktopContent: string
				tabletContent?: string
				mobileContent?: string

				detailLink?: { href: string; useGatsbyLink: boolean; text?: string }

				mediaContent: JSX.Element
				backdropContent: JSX.Element
			}>,
		};
	}

	return {
		features: [
			{
				tabName: "Доставка",
				desktopTitle: "Доставляй в два клика",
				mobileTitle: "Доставляй в два клика",
				desktopContent:
					"Создай приложение и сайт с возможностью доставки — быстро и бесплатно. Подключи основные сервисы или организуй собственных курьеров. Обрабатывай заказы доставки с того же терминала, что стоит в зале. Учет и аналитика формируется автоматически.",
				detailLink: pagesLinks.delivery,
				mediaContent: (
					<>
						<StaticImage
							className={mediaContentStyles.deliveryImage}
							src="./assets/delivery-feature_new.png"
							breakpoints={[300, 600, 808, 2616, 817, 2634]}
							sizes={
								"(max-width: 500px) 300px, (max-width: 1319px) 808px, (min-width: 1320px) 1817px"
							}
							alt={"Доставка для ресторанного бизнеса"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={100}
						/>

						<StaticImage
							className={mediaContentStyles.deliveryImage_tablet_kz}
							src="./assets/delivery-feature_tablet1_ru.png"
							breakpoints={[300, 600, 808, 1616, 817, 1634]}
							sizes={
								"(max-width: 500px) 300px, (max-width: 1319px) 808px, (min-width: 1320px) 817px"
							}
							alt={"Доставка для ресторанного бизнеса"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>

						<StaticImage
							className={mediaContentStyles.deliveryImage_mob_kz}
							src="./assets/delivery-feature_mob_ru.png"
							breakpoints={[300, 600, 808, 1616, 817, 1634]}
							sizes={
								"(max-width: 500px) 300px, (max-width: 1319px) 808px, (min-width: 1320px) 817px"
							}
							alt={"Доставка для ресторанного бизнеса"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					</>
				),
				backdropContent: (
					<>
						<DeliveryBackdropIcon
							className={backdropIconsStyles.deliveryBackdropIconLeft}
						/>

						<DeliveryBackdropIcon
							className={backdropIconsStyles.deliveryBackdropIconRight}
						/>
					</>
				),
			},
			{
				tabName: "Карта столов",
				desktopTitle: "Карты, деньги, два стола",
				desktopContent:
					"Схема столов на терминале — удобный способ ориентироваться в заказах. Официанты не забудут про гостей,\nа если те решили пересесть — смогут без проблем перенести заказы на схеме рассадки. Счёт привязан к\nстолу, но при необходимости его можно разделить или объединить.",
				tabletContent:
					"Схема столов на терминале — удобный способ ориентироваться в заказах. Официанты не забудут про гостей,\nа если те решили пересесть — смогут без проблем перенести заказы на схеме рассадки. Счёт привязан к столу, но при необходимости его можно разделить или объединить.",
				detailLink: pagesLinks.terminal,
				mediaContent: (
					<StaticImage
						className={classNames(
							mediaContentStyles.centeredInterfaceImage,
							mediaContentStyles.tablesMapImage,
						)}
						src="./assets/map-feature.png"
						breakpoints={[277, 554, 808, 1616, 770, 1540]}
						sizes={
							"(max-width: 500px) 277px, (max-width: 1319px) 808px, (min-width: 1320px) 770px"
						}
						alt={"Карта столов для ресторана"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>
				),
				backdropContent: (
					<>
						<TableMapBackdropIcon
							className={backdropIconsStyles.tableMapBackdropIconLeft}
						/>

						<TableMapBackdropIcon
							className={backdropIconsStyles.tableMapBackdropIconRight}
						/>
					</>
				),
			},
			{
				tabName: "Модификаторы",
				desktopTitle: "Тонкая настройка блюда",
				desktopContent:
					"Настрой блюдо по запросам гостя прямо на терминале. Каша на овсяном молоке, окрошка на кефире —\nтеперь не надо бегать на кухню и передавать лично повару. Все автоматически отражается в заказе.",
				detailLink: pagesLinks.terminal,
				mediaContent: (
					<StaticImage
						className={classNames(
							mediaContentStyles.centeredInterfaceImage,
							mediaContentStyles.centeredInterfaceImageSm,
						)}
						src="./assets/modifiers-feature.png"
						breakpoints={[277, 554, 808, 1616, 770, 1540]}
						sizes={
							"(max-width: 500px) 277px, (max-width: 1319px) 808px, (min-width: 1320px) 770px"
						}
						alt={"Модификаторы блюд в Quick Resto"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>
				),
				backdropContent: (
					<>
						<ModifiersBackdropLeftIcon
							className={backdropIconsStyles.modifiersBackdropLeftIcon}
						/>

						<ModifiersBackdropRightIcon
							className={backdropIconsStyles.modifiersBackdropRightIcon}
						/>
					</>
				),
			},
			{
				tabName: "Акции и бонусы",
				desktopTitle: "Внимание к каждому гостю",
				desktopContent:
					"Встречай гостя так, как будто давно его ждешь. Предлагай акции, учитывай бонусные баллы,\nсканируй карты лояльности прямо на терминале.",
				detailLink: pagesLinks.marketing,
				mediaContent: (
					<StaticImage
						className={classNames(
							mediaContentStyles.centeredInterfaceImage,
							mediaContentStyles.centeredInterfaceImageSm,
						)}
						src="./assets/discounts-feature.png"
						breakpoints={[277, 554, 808, 1616, 770, 1540]}
						sizes={
							"(max-width: 500px) 277px, (max-width: 1319px) 808px, (min-width: 1320px) 770px"
						}
						alt={"Бонусные программы для кафе"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>
				),
				backdropContent: (
					<>
						<CrmBackdropIcon
							className={backdropIconsStyles.crmBackdropIconLeft}
						/>

						<CrmBackdropIcon
							className={backdropIconsStyles.crmBackdropIconRight}
						/>
					</>
				),
			},
		] as Array<{
			tabName: string
			// Заголовки могут быть для разных раскладок
			desktopTitle: string
			tabletTitle?: string
			mobileTitle?: string

			// Текстовый контент может быть для разных
			desktopContent: string
			tabletContent?: string
			mobileContent?: string

			detailLink?: { href: string; useGatsbyLink: boolean; text?: string }

			mediaContent: JSX.Element
			backdropContent: JSX.Element
		}>,
	};
};

