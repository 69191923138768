import * as React from "react"
import { SVGProps } from "react"

const CrmBackdropIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 377 352"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M.33 166.829 0 135.348C0 88.001 0 64.286 9.255 46.19A84.613 84.613 0 0 1 46.19 9.172C64.286 0 88 0 135.43 0h105.428c47.347 0 71.062 0 89.157 9.172a84.783 84.783 0 0 1 37.019 37.018c9.171 18.096 9.171 41.811 9.171 89.158v31.482c0 47.346 0 71.061-9.171 89.157a84.78 84.78 0 0 1-37.019 37.018c-17.903 9.074-41.307 9.171-87.655 9.172l-45.828 45.827c-4.655 4.655-12.202 4.655-16.857 0l-45.827-45.827c-46.158-.002-69.476-.124-87.327-9.172a84.613 84.613 0 0 1-36.936-37.018C.331 237.891.331 214.176.331 166.829Zm180.656-66.418c-19.878-19.877-52.104-19.877-71.982 0-19.877 19.877-19.877 52.104 0 71.981l72.691 72.691a9.057 9.057 0 0 0 12.808.001l72.693-72.692c19.876-19.877 19.876-52.104 0-71.981-19.878-19.877-52.104-19.877-71.982 0l-3.911 3.912a4.53 4.53 0 0 1-6.405 0l-3.912-3.912Z"
			fill="#E5EEFF"
		/>
	</svg>
)

export default CrmBackdropIcon
