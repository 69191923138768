import React from "react"
import classNames from "classnames"

import FeaturesGallery from "../../../components/_V2/FeaturesGallery"

import styles from "./styles.module.scss"
import mediaContent from "./mediaContent.module.scss"

import {getData} from "./data"
import {useLocalizationContext} from "../../../localization/useLocalizationContext";

interface Props {
	className?: string
}

export default function MainFeatures(props: Props) {
	const localizationContext = useLocalizationContext()
	const data = getData(localizationContext.locale)

	return (
		<section className={classNames(styles.section, props.className)}>
			<FeaturesGallery
				key={localizationContext.locale}
				className={styles.featuresGallery}
				contentWrapper={classNames(mediaContent.contentWrapper, {[mediaContent.contentWrapper__kz]: localizationContext.locale !== 'ru-RU'})}
				theme={"fuchsia"}
				features={data.features}
			/>
		</section>
	)
}
