import React from "react"
import classNames from "classnames"

import ChevronRight from "../../../assets/_V2/svg/ChevronRight"

import styles from "./styles.module.scss"

import { getData } from "./data"
import AdaptiveLink from "../AdaptiveLink"
import {useLocalizationContext} from "../../../localization/useLocalizationContext";

interface Props {
	className?: string
	title: string | JSX.Element
}

export default function Community(props: Props) {
	const localizationContext = useLocalizationContext()
	const data = getData(localizationContext.locale)
	return (
		<section className={classNames(styles.community, props.className)}>
			<h2 className={styles.title}>{props.title}</h2>

			<div className={styles.channels}>
				<figure className={styles.channels__backdrop} />

				{data.communityChats.map(item => (
					<AdaptiveLink
						className={classNames(styles.communityChat, item.className)}
						href={item.link.href}
						rel="nofollow"
						useGatsbyLink={item.link.useGatsbyLink}
						key={item.link.href}
					>
						{/* SVG-подложка */}
						{item.svgBackdrop as JSX.Element}

						{item.icon as JSX.Element}

						<div className={styles.communityChat__textsContainer}>
							<p className={styles.communityChat__titleContainer}>
								<span className={styles.communityChat__titleText}>
									{item.title}
								</span>

								<ChevronRight className={styles.communityChat__titleIcon} />
							</p>

							<p className={styles.communityChat__description}>
								{item.description}
							</p>
						</div>
					</AdaptiveLink>
				))}
			</div>
		</section>
	)
}
