import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { FeatureGalleryItem } from "../../../components/_V2/FeaturesGallery";
import { pagesLinks } from "../../../pages-data/_V2/common/links";
import mediaContent from "./mediaContent.module.scss";
import { Locales } from "../../../localization/types";

export const getData = (locale: Locales) => {
	if (locale === "ru-KZ") {
		return {
			features: [
				{
					tabName: "CRM",
					desktopTitle: "Дай гостям то, \nчто им понравится",
					desktopContent:
						"Создай опыт гостя по-новому. Используй различные инструменты CRM для того, чтобы гости приходили \nчаще и с большим удовольствием.",
					tabletContent:
						"Создай опыт гостя по-новому. Используй различные инструменты CRM для того, чтобы гости приходили чаще и с большим удовольствием.",
					mobileContent:
						"Создай опыт гостя по-новому. Используй различные инструменты CRM для того, чтобы гости приходили чаще и с большим удовольствием.",
					detailLink: pagesLinks.marketing,
					mediaContent: (
						<StaticImage
							className={mediaContent.imageWrapper}
							src="./assets/crm_kz.png"
							breakpoints={[288, 570, 811, 1622, 674, 1348]}
							sizes={
								"(max-width: 500px) 288px, (max-width: 1319px) 811px, (min-width: 1320px) 674px"
							}
							alt={"CRM система в ресторане"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
				},
				{
					tabName: "Акции",
					desktopTitle: "Возьми от акций всё",
					desktopContent:
						"Решай бизнес-задачи с помощью акционных \nпрограмм. Правильно настроенные механики стимулируют спрос вне пикового времени, \nувеличивают средний чек и расширяют аудиторию \nгостей.",
					tabletContent:
						"Решай бизнес-задачи с помощью акционных программ. Правильно настроенные механики стимулируют спрос вне пикового времени, увеличивают средний чек и расширяют аудиторию гостей.",
					mobileContent:
						"Решай бизнес-задачи с помощью акционных программ. Правильно настроенные механики стимулируют спрос вне пикового времени, увеличивают средний чек и расширяют аудиторию гостей.",
					detailLink: pagesLinks.marketing,
					mediaContent: (
						<StaticImage
							className={mediaContent.imageWrapper}
							src="./assets/discounts_kz.png"
							breakpoints={[288, 570, 811, 1622, 674, 1348]}
							sizes={
								"(max-width: 500px) 288px, (max-width: 1319px) 811px, (min-width: 1320px) 674px"
							}
							alt={"Акционные программы в ресторане"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
				},
				{
					tabName: "Бонусные счета",
					desktopTitle: "Кто не любит \nплатить баллами",
					desktopContent:
						"Бонусные баллы и счета — отличный способ удержать гостя. Накопление и оплата баллами создают выгоду и вырабатывают привычку \nк посещению. Открой личный счет гостю и покажи, насколько он ценен.",
					detailLink: pagesLinks.marketing,
					mediaContent: (
						<StaticImage
							className={mediaContent.imageWrapper}
							src="./assets/bonus_kz.png"
							breakpoints={[288, 570, 811, 1622, 674, 1348]}
							sizes={
								"(max-width: 500px) 288px, (max-width: 1319px) 811px, (min-width: 1320px) 674px"
							}
							alt={"Бонусные счета в кафе"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
				},
				{
					tabName: "Спецпредложения",
					desktopTitle: "Прибыльная\nкомбинация",
					desktopContent:
						"Продавай блюда из старт-листов и объединяй маржинальные позиции в специальное меню. Предложи гостю выгоду и новый опыт, от такого будет сложно отказаться!",
					mobileContent: (
						<>
							Продавай блюда из&nbsp;старт-листов и&nbsp;объединяй маржинальные позиции в&nbsp;специальное меню.
							Предложи гостю выгоду и&nbsp;новый опыт, от&nbsp;такого будет сложно отказаться!
						</>
					),
					detailLink: pagesLinks.marketing,
					mediaContent: (
						<StaticImage
							className={mediaContent.imageWrapper}
							src="./assets/special_kz.png"
							breakpoints={[285, 570, 811, 1622, 680, 1360]}
							sizes={
								"(max-width: 500px) 285px, (max-width: 1319px) 811px, (min-width: 1320px) 680"
							}
							alt={"Спецпредложения в ресторане"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
				},
			],
		} as {
			features: Array<FeatureGalleryItem>
		};
	}

	if (locale === "kz-KZ") {
		return {
			features: [
				{
					tabName: "CRM",
					desktopTitle: "Қонақтарға ұнайтын нәрсесін бер",
					desktopContent:
						"Қонақтың тәжірибесін жаңадай жаса. Қонақтар жиірек және көбірек рақат алып келуі үшін әртүрлі CRM құралдарын пайдаланыңыз.",
					detailLink: pagesLinks.marketing,
					mediaContent: (
						<StaticImage
							className={mediaContent.imageWrapper}
							src="./assets/crm_kz.png"
							breakpoints={[288, 570, 811, 1622, 674, 1348]}
							sizes={
								"(max-width: 500px) 288px, (max-width: 1319px) 811px, (min-width: 1320px) 674px"
							}
							alt={"CRM система в ресторане"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
				},
				{
					tabName: "Акциялар",
					desktopTitle: "Акциялардан бәрін алыңыз",
					desktopContent:
						"Бизнес-есептерді акциялық бағдарламалар көмегімен шешіңіз. Дұрыс теңшелген механикалар шыңдық уақыттан тыс сұранысты ынталандырады, орташа чекті ұлғайтады және қонақтар аудиториясын кеңейтеді.",
					detailLink: pagesLinks.marketing,
					mediaContent: (
						<StaticImage
							className={mediaContent.imageWrapper}
							src="./assets/discounts_kz.png"
							breakpoints={[288, 570, 811, 1622, 674, 1348]}
							sizes={
								"(max-width: 500px) 288px, (max-width: 1319px) 811px, (min-width: 1320px) 674px"
							}
							alt={"Акционные программы в ресторане"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
				},
				{
					tabName: "Бонустық шоттар",
					desktopTitle: "Баллдармен төлеу кімге ұнамайды дейсіз",
					desktopContent:
						"Бонустық баллдар мен шоттар — қонақты ұстап қалудың тамаша тәсілі. Баллдарды жинау және олармен төлеу пайда жасайды және келуге әдетті қалыптастырады. Қонаққа жеке шотты ашыңыз және оның қаншалықты құнды екенін көрсетіңіз.",
					detailLink: pagesLinks.marketing,
					mediaContent: (
						<StaticImage
							className={mediaContent.imageWrapper}
							src="./assets/bonus_kz.png"
							breakpoints={[288, 570, 811, 1622, 674, 1348]}
							sizes={
								"(max-width: 500px) 288px, (max-width: 1319px) 811px, (min-width: 1320px) 674px"
							}
							alt={"Бонусные счета в кафе"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
				},
				{
					tabName: "Арнайы ұсыныстар",
					desktopTitle: "Пайдалы\nкомбинация",
					desktopContent:
						"Старт-листтерден тағамдарды сатыңыз және маржиналдық позицияларды арнайы мәзірге біріктіріңіз. Қонаққа пайда мен жаңа тәжірибені ұсынып көріңіз, бұдан бас тарту қиын болады!",
					detailLink: pagesLinks.marketing,
					mediaContent: (
						<StaticImage
							className={mediaContent.imageWrapper}
							src="./assets/special_kz.png"
							breakpoints={[285, 570, 811, 1622, 680, 1360]}
							sizes={
								"(max-width: 500px) 285px, (max-width: 1319px) 811px, (min-width: 1320px) 680"
							}
							alt={"Спецпредложения в ресторане"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
				},
			],
		} as {
			features: Array<FeatureGalleryItem>
		};
	}

	if (locale === "ru-BY") {
		return {
			features: [
				{
					tabName: "CRM",
					desktopTitle: "Дай гостям то, \nчто им понравится",
					desktopContent:
						"Создай опыт гостя по-новому. Используй различные инструменты CRM для того, чтобы гости приходили \nчаще и с большим удовольствием.",
					tabletContent:
						"Создай опыт гостя по-новому. Используй различные инструменты CRM для того, чтобы гости приходили чаще и с большим удовольствием.",
					mobileContent:
						"Создай опыт гостя по-новому. Используй различные инструменты CRM для того, чтобы гости приходили чаще и с большим удовольствием.",
					detailLink: pagesLinks.marketing,
					mediaContent: (
						<StaticImage
							className={mediaContent.imageWrapper}
							src="./assets/crm_by.png"
							breakpoints={[288, 570, 811, 1622, 674, 1348]}
							sizes={
								"(max-width: 500px) 288px, (max-width: 1319px) 811px, (min-width: 1320px) 674px"
							}
							alt={"CRM система в ресторане"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
				},
				{
					tabName: "Акции",
					desktopTitle: "Делай выгодные предложения",
					desktopContent:
						"Решай бизнес-задачи с помощью акционных \nпрограмм. Правильно настроенные механики стимулируют спрос вне пикового времени, увеличивают средний чек и расширяют аудиторию \nгостей.",
					tabletContent: (
						<>
							Решай бизнес-задачи с&nbsp;помощью акционных программ. Правильно настроенные акции предложат посетить
							заведение по&nbsp;праздникам, стимулируют спрос вне пикового времени, увеличат средний чек и&nbsp;расширят
							аудиторию гостей.
						</>
					),
					mobileContent:
						"Решай бизнес-задачи с помощью акционных программ. Правильно настроенные механики стимулируют спрос вне пикового времени, увеличивают средний чек и расширяют аудиторию гостей.",
					detailLink: pagesLinks.marketing,
					mediaContent: (
						<StaticImage
							className={mediaContent.imageWrapper}
							src="./assets/discounts_by.png"
							breakpoints={[288, 570, 811, 1622, 674, 1348]}
							sizes={
								"(max-width: 500px) 288px, (max-width: 1319px) 811px, (min-width: 1320px) 674px"
							}
							alt={"Акционные программы в ресторане"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
				},
				{
					tabName: "Бонусные счета",
					desktopTitle: "Платить бонусами всегда приятно",
					desktopContent:
						"Бонусные баллы и счета — отличный способ удержать гостя. Накопление и оплата баллами создают выгоду и вырабатывают привычку к посещению. Открой личный счет гостю и покажи, насколько он ценен.",
					tabletContent: (
						<>
							Бонусные баллы и&nbsp;счета&nbsp;&mdash; отличный способ удержать гостя. Накопление и&nbsp;оплата баллами
							создают выгоду и вырабатывают&nbsp;привычку к&nbsp;посещению. Открой личный счет гостю и&nbsp;покажи,
							насколько он&nbsp;ценен.
						</>
					),
					mobileContent:
						"Бонусные баллы и счета — отличный способ удержать гостя. Накопление и оплата баллами создают выгоду и вырабатывают привычку к посещению. Открой личный счет гостю и покажи, насколько он ценен.",
					detailLink: pagesLinks.marketing,
					mediaContent: (
						<StaticImage
							className={mediaContent.imageWrapper}
							src="./assets/bonus_by.png"
							breakpoints={[288, 570, 811, 1622, 674, 1348]}
							sizes={
								"(max-width: 500px) 288px, (max-width: 1319px) 811px, (min-width: 1320px) 674px"
							}
							alt={"Бонусные счета в кафе"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
				},
				{
					tabName: "Спецпредложения",
					desktopTitle: "Прибыльная\nкомбинация",
					desktopContent:
						"Продавай блюда из старт-листов и объединяй маржинальные позиции в специальное меню. Предложи гостю выгоду и новый опыт, от такого будет сложно отказаться!",
					detailLink: pagesLinks.marketing,
					mediaContent: (
						<StaticImage
							className={mediaContent.imageWrapper}
							src="./assets/special_by.png"
							breakpoints={[285, 570, 811, 1622, 680, 1360]}
							sizes={
								"(max-width: 500px) 285px, (max-width: 1319px) 811px, (min-width: 1320px) 680"
							}
							alt={"Спецпредложения в ресторане"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
				},
			],
		} as {
			features: Array<FeatureGalleryItem>
		};
	}

	return {
		// TODO: images
		features: [
			{
				tabName: "CRM",
				desktopTitle: "CRM: покори любого гостя",
				desktopContent:
					"Создай опыт гостя по-новому. Используй различные инструменты CRM для того, чтобы гости приходили \nчаще и с большим удовольствием.",
				tabletContent:
					"Создай опыт гостя по-новому. Используй различные инструменты CRM для того, чтобы гости приходили чаще и с большим удовольствием.",
				mobileContent:
					"Создай опыт гостя по-новому. Используй различные инструменты CRM для того, чтобы гости приходили чаще и с большим удовольствием.",
				detailLink: pagesLinks.marketing,
				mediaContent: (
					<StaticImage
						className={mediaContent.imageWrapper}
						src="./assets/crm.png"
						breakpoints={[288, 570, 811, 1622, 674, 1348]}
						sizes={
							"(max-width: 500px) 288px, (max-width: 1319px) 811px, (min-width: 1320px) 674px"
						}
						alt={"CRM система в ресторане"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>
				),
			},
			{
				tabName: "Акции",
				desktopTitle: "Маржинально, со вкусом",
				desktopContent:
					"Решай бизнес-задачи с помощью акционных \nпрограмм. Правильно настроенные акции предложат посетить заведение по праздникам, стимулируют спрос вне пикового времени, увеличат средний чек и расширят аудиторию гостей.",
				tabletContent:
					"Решай бизнес-задачи с помощью акционных программ. Правильно настроенные акции предложат посетить заведение по праздникам, стимулируют спрос вне пикового времени, увеличат средний чек и расширят аудиторию гостей.",
				mobileContent:
					"Решай бизнес-задачи с помощью акционных программ. Правильно настроенные акции предложат посетить заведение по праздникам, стимулируют спрос вне пикового времени, увеличат средний чек и расширят аудиторию гостей.",
				detailLink: pagesLinks.marketing,
				mediaContent: (
					<StaticImage
						className={mediaContent.imageWrapper}
						src="./assets/discounts.png"
						breakpoints={[288, 570, 811, 1622, 674, 1348]}
						sizes={
							"(max-width: 500px) 288px, (max-width: 1319px) 811px, (min-width: 1320px) 674px"
						}
						alt={"Акционные программы в ресторане"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>
				),
			},
			{
				tabName: "Бонусные счета",
				desktopTitle: "Платить баллами всегда приятно",
				desktopContent:
					"Бонусные баллы и счета — отличный способ удержать гостя. Накопление и оплата баллами создают выгоду и вырабатывают привычку к посещению. Открой личный счет гостю и покажи, насколько он ценен.",
				detailLink: pagesLinks.marketing,
				mediaContent: (
					<StaticImage
						className={mediaContent.imageWrapper}
						src="./assets/bonus.png"
						breakpoints={[288, 570, 811, 1622, 674, 1348]}
						sizes={
							"(max-width: 500px) 288px, (max-width: 1319px) 811px, (min-width: 1320px) 674px"
						}
						alt={"Бонусные счета в кафе"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>
				),
			},
			{
				tabName: "Спецпредложения",
				desktopTitle: "Не скидками\nедиными",
				desktopContent:
					"Продавай блюда из старт-листов и объединяй маржинальные позиции в специальное меню. Предложи гостю выгоду и новый опыт, от такого будет сложно отказаться!",
				detailLink: pagesLinks.marketing,
				mediaContent: (
					<StaticImage
						className={mediaContent.imageWrapper}
						src="./assets/special.png"
						breakpoints={[285, 570, 811, 1622, 680, 1360]}
						sizes={
							"(max-width: 500px) 285px, (max-width: 1319px) 811px, (min-width: 1320px) 680"
						}
						alt={"Спецпредложения в ресторане"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>
				),
			},
		],
	} as {
		features: Array<FeatureGalleryItem>
	};
};
