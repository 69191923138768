import React from "react"

import Backdrop from "./assets/Backdrop"
import { getData } from "./data"
import styles from "./styles.module.scss"

import ProductPresentationBase from "../../../components/_V2/ProductPresentationBase"
import {useLocalizationContext} from "../../../localization/useLocalizationContext";

interface Props {
	className?: string
}

export default function ProductPresentation(props: Props) {
	const localizationContext = useLocalizationContext()

	return (
		<ProductPresentationBase
			sectionClass={styles.section}
			containerClass={styles.content}
			data={getData(localizationContext.locale)}
			backdrop={Backdrop}
			backdropClass={styles.backdrop}
			className={props.className}
			additionalTitleClass={styles.title__mob}
			additionalTabletTitleClass={styles.title}
			additionalDescriptionClass={localizationContext.locale !== 'ru-RU' ? styles.subtitle__kz : styles.subtitle}
			additionalRightColumn={styles.rightColumn_kz}
			additionalLeftColumn={localizationContext.locale === 'ru-BY' ? styles.leftColumn : ''}
			adaptiveWrapTextClassName={localizationContext.locale !== 'ru-RU' ? styles.subtext_kz : ''}
		/>
	)
}
