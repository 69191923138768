import * as React from "react"

const Support = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		width={32}
		height={32}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M26 16.959a1 1 0 0 0-2 0h2Zm-18 0a1 1 0 0 0-2 0h2Zm16 0v4h2v-4h-2Zm-16 4v-4H6v4h2Zm8 8a8 8 0 0 1-8-8H6c0 5.523 4.477 10 10 10v-2Zm8-8a8 8 0 0 1-8 8v2c5.523 0 10-4.477 10-10h-2Z"
			fill="#3021E5"
		/>
		<path
			d="M28.987 21.648c0 3.218-1.22 6.152-3.222 8.363M7 23.528H5.14c-1.175 0-2.127-.896-2.127-2v-2.57c0-1.104.952-2 2.127-2H7"
			stroke="#3021E5"
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M25 23.528h1.86c1.175 0 2.127-.896 2.127-2v-2.57c0-1.104-.952-2-2.127-2H25M25 16.959h-7.903a6 6 0 0 1-6-6M5.097 16.959a6 6 0 0 0 6-6"
			stroke="#3021E5"
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M27 12.99c0-6.076-4.925-11-11-11s-11 4.924-11 11"
			stroke="#3021E5"
			strokeWidth={2}
			strokeLinecap="round"
		/>
	</svg>
)

export default Support
