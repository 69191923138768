import * as React from "react"
import { SVGProps } from "react"

const QRCommunityIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="44"
		height="44"
		viewBox="0 0 44 44"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<mask
			id="mask0_10040_215189"
			mask-type="alpha"
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
			width="44"
			height="44"
		>
			<circle cx="22" cy="22" r="22" fill="#D9D9D9" />
		</mask>
		<g mask="url(#mask0_10040_215189)">
			<rect width="44" height="44" fill="white" />
			<rect x="1" y="1" width="1024" height="1024" fill="white" />
			<path
				d="M18.7461 23.9712C18.7461 22.1548 18.7461 21.2307 19.0966 20.5296C19.4153 19.9242 19.8933 19.4143 20.5306 19.0956C21.2317 18.7451 22.1558 18.7451 23.9721 18.7451H25.183C26.9994 18.7451 27.9235 18.7451 28.6246 19.0956C29.23 19.4143 29.7399 19.8923 30.0586 20.5296C30.4091 21.2307 30.4091 22.1548 30.4091 23.9712V25.1821C30.4091 26.9984 30.4091 27.9226 30.0586 28.6236C29.7399 29.2291 29.2619 29.7389 28.6246 30.0576C27.9235 30.4081 26.9994 30.4081 25.183 30.4081H23.9721C22.1558 30.4081 21.2317 30.4081 20.5306 30.0576C19.9251 29.7389 19.4153 29.2609 19.0966 28.6236C18.7461 27.9226 18.7461 26.9984 18.7461 25.1821V23.9712Z"
				fill="#3021E5"
			/>
			<path
				d="M20.0355 26.4753C23.5932 26.4753 26.4773 23.5913 26.4773 20.0336C26.4773 16.4759 23.5932 13.5918 20.0355 13.5918C16.4778 13.5918 13.5938 16.4759 13.5938 20.0336C13.5938 23.5913 16.4778 26.4753 20.0355 26.4753Z"
				fill="#FC0B36"
			/>
			<path
				d="M22.4904 21.1318L21.1328 22.4894L26.0922 26.9362L26.9372 26.0913L22.4904 21.1318Z"
				fill="white"
			/>
			<path
				d="M8.18199 17.6099C7.9798 18.2573 7.59788 18.7316 7.03623 19.0326C6.47459 19.3337 5.87317 19.384 5.232 19.1836C4.58858 18.9828 4.11949 18.5984 3.82474 18.0304C3.52999 17.4625 3.48371 16.8546 3.6859 16.2067C3.86113 15.6437 4.17992 15.2168 4.64227 14.9261C5.10145 14.6348 5.65207 14.5234 6.18837 14.6134L5.81701 15.8057C5.66258 15.8068 5.50995 15.8389 5.36814 15.9C5.23162 15.9593 5.10936 16.047 5.00959 16.1575C4.85906 16.3248 4.7654 16.5355 4.74203 16.7594C4.71867 16.9833 4.76679 17.2088 4.87951 17.4036C5.04453 17.6762 5.30805 17.8748 5.61549 17.9583C5.81701 18.0248 6.03166 18.0412 6.24094 18.0062C6.43527 17.9737 6.61665 17.8876 6.76462 17.7575C6.91397 17.6251 7.02325 17.4535 7.08004 17.2621C7.12779 17.1145 7.14047 16.9578 7.11707 16.8044C7.09367 16.651 7.03484 16.5052 6.94525 16.3785C6.85277 16.2472 6.73442 16.1362 6.59747 16.0523L6.96883 14.8425C7.50532 15.1 7.88566 15.4819 8.10987 15.9883C8.33408 16.4947 8.35812 17.0352 8.18199 17.6099Z"
				fill="#3021E5"
			/>
			<path
				d="M10.4731 13.2074C10.035 13.7803 9.49991 14.1173 8.86772 14.2184C8.23553 14.3195 7.64738 14.1604 7.10326 13.7412C6.55689 13.3238 6.24866 12.7972 6.17856 12.1614C6.10847 11.5256 6.29246 10.9208 6.73055 10.347C7.16593 9.77686 7.69905 9.44279 8.32989 9.34484C8.96073 9.24689 9.54911 9.4064 10.095 9.82337C10.6414 10.2399 10.9503 10.7656 11.0217 11.4005C11.0932 12.0354 10.9103 12.6377 10.4731 13.2074ZM8.77269 12.9755C8.92854 12.9555 9.07861 12.9037 9.21357 12.8232C9.34853 12.7428 9.4655 12.6354 9.5572 12.5078C9.65615 12.3866 9.72872 12.246 9.77026 12.0952C9.81181 11.9443 9.82142 11.7864 9.79848 11.6316C9.73805 11.3186 9.56464 11.0387 9.31124 10.8452C9.05785 10.6517 8.74216 10.5581 8.42425 10.5823C8.26907 10.601 8.11951 10.652 7.98513 10.7318C7.85075 10.8117 7.73449 10.9187 7.64379 11.046C7.5447 11.168 7.47178 11.3091 7.42958 11.4605C7.38737 11.612 7.37678 11.7705 7.39846 11.9262C7.45511 12.241 7.62758 12.5233 7.88192 12.7174C8.13625 12.9115 8.45403 13.0033 8.77269 12.9749V12.9755Z"
				fill="#3021E5"
			/>
			<path
				d="M17.0934 7.91834L16.0251 8.55322L14.4076 6.51512L14.7115 9.34581L14.3975 9.5325L12.052 7.91496L13.0738 10.3096L12.0001 10.9479L10.293 6.74899L11.3498 6.12017L13.5065 7.67705L13.1702 5.03776L14.2317 4.40625L17.0934 7.91834Z"
				fill="#3021E5"
			/>
			<path
				d="M23.1795 7.14319L21.9461 7.29146L21.289 4.77147L20.4216 7.48085L20.059 7.52399L18.5681 5.09768L18.5324 7.70124L17.293 7.84951L17.4325 3.31905L18.6531 3.17213L19.9929 5.46836L20.7572 2.92006L21.9845 2.77246L23.1795 7.14319Z"
				fill="#3021E5"
			/>
			<path
				d="M26.9924 7.77463C26.5566 8.01816 26.0599 8.07073 25.5023 7.93234C24.9447 7.79395 24.5302 7.51514 24.2588 7.09593C23.9865 6.67672 23.9168 6.19977 24.0498 5.66508L24.7117 2.99951L25.9356 3.30348L25.2785 5.95017C25.2509 6.04164 25.2421 6.13773 25.2525 6.23269C25.2629 6.32765 25.2924 6.41953 25.3392 6.50284C25.4422 6.65779 25.5995 6.76861 25.78 6.81351C25.9606 6.8584 26.1515 6.83413 26.3151 6.74546C26.3952 6.69349 26.4641 6.62598 26.5177 6.54691C26.5713 6.46785 26.6085 6.37883 26.6271 6.28514L27.2842 3.63777L28.5082 3.94173L27.8463 6.60798C27.7138 7.14266 27.4291 7.53155 26.9924 7.77463Z"
				fill="#3021E5"
			/>
			<path
				d="M32.9671 6.35041L33.978 6.99675L31.5584 10.771L30.7011 10.221L30.5165 7.21175L29.2029 9.25996L28.1953 8.61362L30.6149 4.83936L31.52 5.41965L31.7013 8.32785L32.9671 6.35041Z"
				fill="#3021E5"
			/>
			<path
				d="M32.2383 11.5613L35.5098 8.49805L36.363 9.40926L33.0915 12.4725L32.2383 11.5613Z"
				fill="#3021E5"
			/>
			<path
				d="M39.388 13.9046L38.4282 14.4701L37.8068 13.4167L34.9087 15.1265L34.2812 14.0623L37.1793 12.3525L36.562 11.3044L37.5217 10.7383L39.388 13.9046Z"
				fill="#3021E5"
			/>
			<path
				d="M41.0642 19.5741L38.0414 18.698L36.3376 19.1158L36.0391 17.8966L37.7429 17.4794L40.0223 15.3193L40.3478 16.6498L38.9695 17.8312L40.738 18.2417L41.0642 19.5741Z"
				fill="#3021E5"
			/>
			<path
				d="M35.8155 26.3898C36.0176 25.7419 36.3996 25.2677 36.9612 24.9671C37.5229 24.6665 38.1243 24.6161 38.7654 24.8161C39.4093 25.0169 39.8784 25.4013 40.1727 25.9693C40.467 26.5372 40.5133 27.1451 40.3115 27.793C40.1363 28.3556 39.8175 28.7824 39.3552 29.0736C38.896 29.3649 38.3454 29.4763 37.8091 29.3863L38.1804 28.1941C38.3349 28.1929 38.4875 28.1609 38.6293 28.0997C38.7657 28.0402 38.8879 27.9525 38.9879 27.8422C39.1378 27.6751 39.2311 27.4649 39.2544 27.2416C39.2778 27.0183 39.23 26.7933 39.1179 26.5988C38.9529 26.3262 38.6894 26.1276 38.3819 26.0441C38.1804 25.9776 37.9658 25.9612 37.7565 25.9962C37.562 26.0286 37.3806 26.115 37.2328 26.2456C37.0834 26.3776 36.974 26.5491 36.9174 26.7403C36.8697 26.8879 36.857 27.0446 36.8804 27.198C36.9038 27.3514 36.9626 27.4972 37.0522 27.6239C37.1447 27.7552 37.263 27.8662 37.4 27.9501L37.0286 29.1551C36.4921 28.8977 36.1118 28.5158 35.8876 28.0094C35.6634 27.503 35.6393 26.9632 35.8155 26.3898Z"
				fill="#3021E5"
			/>
			<path
				d="M33.522 30.7924C33.96 30.2196 34.4952 29.8826 35.1274 29.7815C35.7596 29.6804 36.3486 29.839 36.8945 30.2573C37.4409 30.6743 37.7491 31.2009 37.8192 31.8371C37.8893 32.4733 37.7053 33.0781 37.2672 33.6514C36.8318 34.2216 36.2987 34.5557 35.6679 34.6536C35.0371 34.7516 34.4487 34.5923 33.9028 34.1758C33.3564 33.7584 33.0475 33.2324 32.976 32.598C32.9046 31.9636 33.0866 31.3617 33.522 30.7924ZM35.2224 31.0243C35.0666 31.0443 34.9165 31.0961 34.7815 31.1766C34.6466 31.2571 34.5296 31.3644 34.4379 31.492C34.3389 31.6132 34.2664 31.7538 34.2248 31.9046C34.1833 32.0555 34.1737 32.2134 34.1966 32.3682C34.257 32.6812 34.4305 32.9611 34.6839 33.1546C34.9372 33.3481 35.2529 33.4417 35.5708 33.4176C35.726 33.3988 35.8756 33.3478 36.01 33.268C36.1444 33.1882 36.2606 33.0812 36.3513 32.9539C36.4504 32.8318 36.5233 32.6907 36.5655 32.5393C36.6077 32.3878 36.6183 32.2294 36.5966 32.0737C36.54 31.7588 36.3675 31.4765 36.1132 31.2824C35.8588 31.0883 35.5411 30.9965 35.2224 31.0249V31.0243Z"
				fill="#3021E5"
			/>
			<path
				d="M26.9023 36.0818L27.9706 35.4469L29.5881 37.485L29.2842 34.6543L29.5982 34.4676L31.9437 36.0851L30.9219 33.6905L31.9956 33.0522L33.7027 37.2511L32.646 37.8799L30.4892 36.323L30.8222 38.963L29.7607 39.5945L26.9023 36.0818Z"
				fill="#3021E5"
			/>
			<path
				d="M20.8164 36.8567L22.0498 36.7084L22.7069 39.2284L23.5743 36.5191L23.9369 36.4759L25.4277 38.9022L25.4635 36.2993L26.7029 36.1504L26.5634 40.6808L25.3428 40.8278L24.003 38.5315L23.2387 41.0798L22.0114 41.2274L20.8164 36.8567Z"
				fill="#3021E5"
			/>
			<path
				d="M17.0041 36.2253C17.4399 35.9822 17.9366 35.9296 18.4942 36.0676C19.0518 36.2055 19.4663 36.4843 19.7377 36.904C20.01 37.3232 20.0796 37.8001 19.9466 38.3348L19.2848 41.0004L18.0608 40.6971L18.718 38.0497C18.7455 37.9583 18.7544 37.8622 18.744 37.7672C18.7335 37.6723 18.7041 37.5804 18.6573 37.4971C18.5543 37.3421 18.397 37.2313 18.2164 37.1864C18.0358 37.1415 17.845 37.1658 17.6814 37.2545C17.6012 37.3064 17.5323 37.3739 17.4787 37.453C17.4251 37.5321 17.3879 37.6211 17.3693 37.7148L16.7122 40.3622L15.4883 40.0582L16.1501 37.3919C16.2831 36.8577 16.5678 36.4688 17.0041 36.2253Z"
				fill="#3021E5"
			/>
			<path
				d="M11.0305 37.6472L10.0195 37.0008L12.4391 33.2266L13.2964 33.7765L13.4811 36.7858L14.7946 34.7376L15.8022 35.384L13.3827 39.1582L12.4775 38.5772L12.2989 35.6684L11.0305 37.6472Z"
				fill="#3021E5"
			/>
			<path
				d="M11.7614 32.4387L8.48997 35.502L7.63672 34.5901L10.9082 31.5269L11.7614 32.4387Z"
				fill="#3021E5"
			/>
			<path
				d="M4.60938 30.0955L5.56911 29.53L6.19052 30.5834L9.08861 28.8735L9.71609 29.9377L6.81799 31.6476L7.43535 32.6956L6.47561 33.2618L4.60938 30.0955Z"
				fill="#3021E5"
			/>
			<path
				d="M2.93359 24.4258L5.95637 25.3019L7.66018 24.8841L7.95875 26.1033L6.25494 26.5205L3.97556 28.6772L3.65003 27.3468L5.02831 26.1653L3.26182 25.7569L2.93359 24.4258Z"
				fill="#3021E5"
			/>
			<path
				d="M38.8469 22.6675C39.2154 22.6675 39.5142 22.3687 39.5142 22.0002C39.5142 21.6317 39.2154 21.333 38.8469 21.333C38.4784 21.333 38.1797 21.6317 38.1797 22.0002C38.1797 22.3687 38.4784 22.6675 38.8469 22.6675Z"
				fill="#3021E5"
			/>
			<path
				d="M5.14771 22.6675C5.51621 22.6675 5.81494 22.3687 5.81494 22.0002C5.81494 21.6317 5.51621 21.333 5.14771 21.333C4.7792 21.333 4.48047 21.6317 4.48047 22.0002C4.48047 22.3687 4.7792 22.6675 5.14771 22.6675Z"
				fill="#3021E5"
			/>
		</g>
	</svg>
)

export default QRCommunityIcon
