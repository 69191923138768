import * as React from "react"

const QRCommunityBackdrop = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 378 117"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path d="m.443 58.96 2.525 26.376C4.688 103.29 19.771 117 37.81 117h293.696c16.138 0 30.184-11.034 34.005-26.713l11.218-46.034c5.591-22.94-12.673-44.722-36.238-43.215L33.05 20.695C13.327 21.956-1.44 39.286.443 58.959Z" />
	</svg>
)

export default QRCommunityBackdrop
